import {
  PushMessage,
  PushMessageDoc,
  PushMessageFCMResult,
  PushMessageHeader,
  PushMessagePayload,
} from '@gooduncles/gu-app-schema';

import { formatDate } from 'src/lib/1/date-util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';

import { ChatMessage } from '../schema/chat';

const firebaseManager = FirebaseManager.getInstance();

const pushMessageResultIntialValue: PushMessageFCMResult = {
  requestedAt: null,
  sentAt: null,
  messageId: null,
  badge: null,
  error: null,
};

/**
 * 관리자가 특정 사용자에게 수발신한 메시지를 ChatMessage로 변환합니다.
 */
export const transformPushToChatMessage = (sent: PushMessageDoc[], received: PushMessageDoc[]): ChatMessage[] => {
  // 1. requests와 messages를 chatMessage로 변환합니다.
  const sentMessages: ChatMessage[] = sent.map((message) => ({
    id: message._id,
    type: 'sent',
    title: message.title,
    content: message.body,
    imageUrl: message.imageUrl,
    timestamp: message.createdAt,
  }));

  const receivedMessages: ChatMessage[] = received.map((message) => ({
    id: message._id,
    type: 'received',
    title: message.title,
    content: message.body,
    imageUrl: message.imageUrl,
    timestamp: message.createdAt,
  }));

  const chatMessages = [...sentMessages, ...receivedMessages].sort((a, b) => a.timestamp.localeCompare(b.timestamp));
  return chatMessages;
};

const pushMessageForPartnersPath = 'pushMessageForPartners';
export interface PushRequestOptions {
  notify: boolean;
}
/**
 * 파트너에게 메시지를 보냅니다.
 */
export const createPushMessageForPartner = (
  payload: PushMessagePayload,
  from: string,
  to: string,
  options?: PushRequestOptions
) => {
  const { notify } = options || { notify: true };
  const pushMessageHeader: PushMessageHeader = {
    receiverType: 'partnersUser',
    receiverId: to,
    senderType: 'admin',
    senderId: from,
    notify,
  };

  const pushMessage: PushMessage = {
    ...payload,
    ...pushMessageHeader,
    ...pushMessageResultIntialValue,
    createdAt: formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss+0900"),
    readAt: null,
    isRequested: null,
    fcmResults: [],
  };
  return firebaseManager.createDoc(pushMessageForPartnersPath, undefined, pushMessage);
};
