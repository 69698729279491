import {
  OrderDoc,
  OrderProduct,
  PaymentStatusCode,
  Product,
  ProductDoc,
  ProductStateCode,
  StoreIssueDoc,
} from '@gooduncles/gu-app-schema';
import { ColumnState, NewValueParams, RowClassParams, ValueSetterParams } from 'ag-grid-community';
import { GridApi, RowNode } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { message, notification } from 'antd';
import { Timestamp, deleteField } from 'firebase/firestore';
import { get, has, isEqual, isNumber, set } from 'lodash-es';
import { callSendKakaoAlimTalkForChangeVolume } from 'src/utils/firebase-callable';
import { v4 } from 'uuid';

import { CommerceIssueSubCategory, productCategories, senderKey } from '../1/constant';
import { dateFormat06, dateFormat09, formatDate, getKakaoTalkRequestOptions, orderDateFormat02 } from '../1/date-util';
import { ProductSoldOutTemplate } from '../1/schema-alimtalk-template';
import { SupplierPurchaseConversionItem } from '../1/schema-purchase-conversion';
import {
  errorObjectToString,
  formatNumber,
  getLocalStorageValue,
  parseIfObject,
  phoneNumberRegex,
  setLocalStorageValue,
  sleep,
} from '../1/util';
import { FirebaseManager } from '../3/firebase-manager';
import { SupplierDoc } from '../3/schema-supplier';
import { getAlimtalkMessage, getUser } from '../4/firebase-short-cut';
import { organizeKakaotalkResultMessage } from '../4/nhn-notification-util';
import { setProductSupplier, updateSupplierConversionItem } from '../4/supplier-util';
import { ConsoleLogger } from '../5/logger';
import { calcProductUnitVolume } from '../5/order-util';

const firebaseManager = FirebaseManager.getInstance();
const logger = ConsoleLogger.getInstance();

export const numberFormatter = (params: any, fraction = 0, replacer = '') => {
  const number = params.value;
  if (number === undefined || number === null) {
    return replacer;
  }
  const toNumber = isNumber(number) ? number : Number(number);
  return isNaN(toNumber) ? replacer : formatNumber(toNumber, fraction);
};

export const timestampFormatter = (params: any) => {
  const timestamp = params.value as Timestamp;
  return timestamp ? orderDateFormat02(timestamp.toDate().toISOString()) : '';
};

export const timestampFormatter2 = (params: any) => {
  const timestamp = params.value as Timestamp;
  return timestamp ? dateFormat09(timestamp.toDate()) : '';
};

export const timestampFormatter3 = (params: any) => {
  const timestamp = params.value as Timestamp;
  return timestamp ? formatDate(timestamp.toDate(), 'L월 d일(EEEEEE) HH:mm:ss') : '';
};

export const defaultValueGetter = (params: any) => {
  const value = (params.colDef.field as string)?.split('.')?.reduce((acc, cur) => acc?.[cur], params.data);
  return value ? value : 'unknown';
};

export const unknownValueCellClass = (params: any) => {
  return params.value === 'unknown' ? 'unknown' : '';
};

const submittedOrderMsg = /주문완료/;
const canceledOrderMsg = /주문취소/;
export const orderStatusCellClass = (params: any) => {
  return submittedOrderMsg.test(params.value) ? 'submitted' : canceledOrderMsg.test(params.value) ? 'canceled' : '';
};

/**
 * @param requiredFields data에서 참조해야하는 필드 path ex) ['a', 'b.c', 'd.e.f']
 * @param validation 추가로 해야하는 검증
 * @returns
 */
export function onValueSetterWithValidation<T extends object>(
  params: ValueSetterParams<T>,
  requiredFields?: string[],
  validation?: (value: any) => boolean,
  options?: {
    type?: 'number' | 'array' | 'boolean';
    /** null값을 업데이트할 것인가? true: yes */
    nullable?: boolean;
    /** 필드 삭제 허용 */
    removable?: boolean;
    /** 필드 삭제 대신 null값으로 변경 */
    nullify?: boolean;
    /** string값을 array로 자를때 사용할 구분자 */
    arrayDelimiter?: string;
  }
) {
  const { type = 'string', nullable = false, arrayDelimiter = ',', removable = false, nullify = false } = options ?? {};
  const data: T | undefined = params.data;

  if (!data) {
    return false;
  }

  if (params.newValue === undefined || params.newValue === null) {
    if (removable) {
      set(params.data, params.colDef.field as string, undefined);
      return true;
    } else if (nullify) {
      set(params.data, params.colDef.field as string, null);
      return true;
    } else {
      message.error('값을 입력해주세요.');
      return false;
    }
  }

  if (validation && !validation(params.newValue)) {
    message.error('값이 올바르지 않습니다.');
    return false;
  }

  if (requiredFields && requiredFields.length > 0) {
    for (const field of requiredFields) {
      if (!has(data, field)) {
        message.error(`필수 필드가 누락되었습니다. field: ${field as string}, 개발자에게 알려주세요.`);
        return false;
      }
    }
  }

  if (type === 'number') {
    const toNumber = Number(params.newValue);
    if (isNaN(toNumber)) {
      message.error('숫자만 입력 가능합니다.');
      return false;
    }
    set(params.data, params.colDef.field as string, toNumber);
  } else if (type === 'array') {
    const newValue = params.newValue
      .split(arrayDelimiter)
      .map((v: string) => v.trim())
      .filter((v: string) => v.length > 0);
    const nullableValue = newValue.length === 0 && nullable ? null : newValue;
    set(params.data, params.colDef.field as string, nullableValue);
  } else if (type === 'string') {
    const nullableValue = params.newValue.length === 0 && nullable ? null : params.newValue;
    set(params.data, params.colDef.field as string, nullableValue);
  }
  return true;
}

/**
 * @param paths field path가 직접적인 경로가 아닌 경우 따로 입력한다.
 */
export function onCellValueChangedWithUpdate<T>(logName: string) {
  return async (
    params: NewValueParams<T>,
    collection: string,
    docId?: string,
    paths?: string,
    removable?: boolean,
    nullable?: boolean
  ) => {
    const field = params.colDef.field;
    if (!field) {
      message.error('수정할 수 없는 필드정보입니다.');
      return;
    }

    const id: string = docId ?? (params.data as any)?._id;
    if (!id) {
      message.error('id값이 없습니다.');
      return;
    }

    const newValue = get(params.data, field);
    // TODO: 코드정리
    if (removable && (newValue == undefined || newValue == null)) {
      try {
        await firebaseManager.updateDoc(collection, id, { [paths ?? field]: deleteField() });
        logger.logConsole(`${logName} - ${collection}/${id}.${field}:: ${parseIfObject(params.oldValue)} -> 삭제`);
        message.success('변경완료');
      } catch (error: any) {
        console.error(error);
        message.error(error?.message ?? '알 수 없는 에러 발생!');
        logger.logConsole(`${logName} - ${collection}/${id}.${field}:: ${parseIfObject(error)}`, {
          level: 'error',
        });
      }
    }

    if (newValue !== undefined && newValue !== params.oldValue) {
      // null값 허용 여부
      if (newValue === null && !nullable) {
        return;
      }

      try {
        await firebaseManager.updateDoc(collection, id, { [paths ?? field]: newValue });
        logger.logConsole(
          `${logName} - ${collection}/${id}.${field}:: ${parseIfObject(params.oldValue)} -> ${parseIfObject(newValue)}`
        );
        message.success('변경완료');
      } catch (error: any) {
        console.error(error);
        message.error(error?.message ?? '알 수 없는 에러 발생!');
        logger.logConsole(`${logName} - ${collection}/${id}.${field}:: ${parseIfObject(error)}`, {
          level: 'error',
        });
      }
    }
  };
}

/**
 * indexing을 줄이기 위한 편법으로 모든 상품에 'all' 카테고리를 넣어줘야한다.
 * 이를 위해 카테고리 변경의 경우 별도로 처리한다.
 * @param logName
 * @returns
 */
export function onCellValueChangedWithUpdateForCategories(logName: string) {
  return async (params: NewValueParams<ProductDoc>) => {
    const productDoc = params.data;
    const field = params.colDef.field;
    try {
      if (field !== 'categories') {
        message.error('수정할 수 없는 필드정보입니다.');
        return;
      }

      const newValue = get(params.data, 'categories') as any as string;

      if (Object.keys(productCategories).includes(newValue)) {
        await firebaseManager.updateDoc('product', productDoc._id, {
          categories: [newValue, 'all'],
        });
      } else {
        message.error('카테고리를 확인해주세요.');
        return;
      }
    } catch (error: any) {
      console.error(error);
      message.error(error?.message ?? '알 수 없는 에러 발생!');
      logger.logConsole(`${logName} - product/${productDoc._id}.${field}:: ${parseIfObject(error)}`, {
        level: 'error',
      });
    }
  };
}

export function onCellValueChangedWithUpdateForSuppliers(logName: string) {
  return async (params: NewValueParams<ProductDoc>) => {
    const productDoc = params.data;
    const field = params.colDef.field;
    try {
      if (field !== 'suppliers') {
        message.error('수정할 수 없는 필드정보입니다.');
        return;
      }

      const newValue = get(params.data, 'suppliers') as any as string;
      const suppliers = newValue.split(',');

      await firebaseManager.updateDoc('product', productDoc._id, {
        suppliers,
      });
    } catch (error: any) {
      console.error(error);
      message.error(error?.message ?? '알 수 없는 에러 발생!');
      logger.logConsole(`${logName} - product/${productDoc._id}.${field}:: ${parseIfObject(error)}`, {
        level: 'error',
      });
    }
  };
}

/**
 * 배송비를 수정한다.
 */
export function onCellValueChangedWithUpdateForDeliveryFee(logName: string) {
  return async (params: NewValueParams<OrderDoc>) => {
    const orderDoc = params.data;
    const field = params.colDef.field;
    try {
      if (field !== 'deliveryFee') {
        message.error('수정할 수 없는 필드정보입니다.');
        return;
      }

      const deliveryFee = get(params.data, 'deliveryFee') as number;
      const grandTotal = orderDoc.totalAmount + deliveryFee;

      await firebaseManager.updateDoc('order', orderDoc._id, {
        deliveryFee,
        paidAmount: grandTotal,
        grandTotal,
      });
    } catch (error: any) {
      console.error(error);
      message.error(error?.message ?? '알 수 없는 에러 발생!');
      logger.logConsole(`${logName} - order/${orderDoc._id}.${field}:: ${parseIfObject(error)}`, {
        level: 'error',
      });
    }
  };
}

/**
 * 주문의 상품을 수정한다.
 */
export function onCellValueChangedWithUpdateForOrderProduct(logName: string) {
  return async (
    params: NewValueParams<
      OrderProduct & {
        order: OrderDoc;
      }
    >,
    skipKakaoMessage?: boolean
  ) => {
    const order = params.data.order;
    const field = params.colDef.field;
    if (field !== 'volume') {
      message.error('수정할 수 없는 필드정보입니다.');
      return;
    }

    try {
      // 1. 변경된 수량을 주문 내용에 반영
      const newValue = get(params.data, 'volume');
      const newOrderProducts = order.products.map((orderProduct) => {
        if (orderProduct.productId === params.data.productId) {
          return {
            ...orderProduct,
            state: ProductStateCode.STOCK,
            volume: newValue,
          };
        }
        return orderProduct;
      });
      const totalAmount = newOrderProducts.reduce((acc, cur) => acc + cur.price * cur.volume, 0);
      const grandTotal = totalAmount + (order.deliveryFee ?? 0);

      // 2. 주문 업데이트
      await firebaseManager.updateDoc('order', order._id, {
        products: newOrderProducts,
        totalAmount,
        paidAmount: grandTotal,
        grandTotal,
      });
      const newUnitVolume = calcProductUnitVolume(params.data.measure, newValue);

      // 3. 변경 내용 기록
      logger.logOrder('주문 상품 수량 변경', {
        orderId: order._id,
        storeId: order.storeId,
        before: order,
        after: {
          ...order,
          products: newOrderProducts,
          totalAmount,
          paidAmount: grandTotal,
          grandTotal,
        },
        reason: '실거래가 반영',
        reasonForUser: `고객님이 구매하신 ${params.data.fullName}의 출고량은 ${newUnitVolume}이며 ${formatNumber(
          params.data.price * newValue
        )}원으로 적용됩니다. 이용해주셔서 감사합니다.`,
      });

      notification.success({
        message: '주문 상품 수정 완료',
        description: `총 금액: ${order.grandTotal} -> ${grandTotal}`,
      });

      if (skipKakaoMessage) {
        return;
      }

      // 4. 카톡 메시지 발송
      const messageKey = v4();
      message.open({
        type: 'loading',
        content: '카톡 메시지 발송 요청을 진행합니다...',
        duration: 0,
        key: messageKey,
      });
      const user = await getUser(order.userId);
      if (!user?.userTel) {
        throw new Error('유효한 전화번호를 찾지 못했습니다.');
      }

      if (!senderKey) {
        throw new Error('카카오 알림톡 발송을 위한 senderKey가 없습니다.');
      }

      // 5. 새벽 알림 수신을 꺼리는 고객을 위한 옵션 설정
      const requestDate = user.disableNotificationAtDawn ? getKakaoTalkRequestOptions() : undefined;

      const result = await callSendKakaoAlimTalkForChangeVolume({
        senderKey,
        templateCode: 'volume-change',
        recipientList: [
          {
            recipientNo: user?.userTel ?? '',
            templateParameter: {
              date: dateFormat06(new Date(order.orderDate)),
              product: params.data.fullName,
              volume: newUnitVolume,
              price: formatNumber(params.data.price * newValue),
            },
            recipientGroupingKey: user?.storeId,
          },
        ],
        ...(requestDate ? { requestDate } : {}),
      });
      // 요청이 성공하면, 요청 로딩 창을 받는다.
      message.destroy(messageKey);

      if (result.data.result !== 'success') {
        throw new Error(result.data.reason);
      }

      if (!result.data.alimtalkMessageDocId) {
        throw new Error('요청은 성공했으나, ID를 받지 못했습니다.');
      }

      const alimtalkMessageDoc = await getAlimtalkMessage<ProductSoldOutTemplate>(result.data.alimtalkMessageDocId);
      if (!alimtalkMessageDoc) {
        throw new Error('요청은 성공했으나, 메시지 정보를 받지 못했습니다.');
      }

      // 요청 결과를 메시지로 정리한다.
      const description = organizeKakaotalkResultMessage(alimtalkMessageDoc.recipientWithResultList);

      notification.success({
        message: `메시지 ${requestDate ? '⏰ 예약' : '즉시'}발송 요청 완료`,
        description,
        className: 'pre-line-notification',
      });
    } catch (error) {
      console.error(error);
      const errorMessage = errorObjectToString(error);
      message.error(errorMessage ?? '알 수 없는 에러 발생!');
      logger.logConsole(`${logName} - order/${order._id}.${field}:: ${errorMessage}`, {
        level: 'error',
      });
    }
  };
}

export function onCellValueChangedWithUpdateForIssueCg(logName: string) {
  return async (params: NewValueParams, collection: string, docId?: string) => {
    const field = params.colDef.field;
    if (!field) {
      message.error('수정할 수 없는 필드정보입니다.');
      return;
    }

    if (!docId) {
      message.error('id값이 없습니다.');
      return;
    }

    const newCategory = get(params.data, field);
    if (newCategory !== undefined && newCategory !== null && newCategory !== params.oldValue) {
      try {
        const subCategories = CommerceIssueSubCategory[newCategory];
        await firebaseManager.updateDoc(collection, docId, {
          category: newCategory,
          subCategory: subCategories?.[0] ?? null,
        });
        logger.logConsole(
          `${logName} - ${collection}/${docId}.${field}:: ${parseIfObject(params.oldValue)} -> ${parseIfObject(
            newCategory
          )}`
        );
        message.success('변경완료');
      } catch (error: any) {
        console.error(error);
        message.error(error?.message ?? '알 수 없는 에러 발생!');
        logger.logConsole(`${logName} - ${collection}/${docId}.${field}:: ${parseIfObject(error)}`, {
          level: 'error',
        });
      }
    }
  };
}

export async function saveGridState(gridRef: AgGridReact | null, table: string) {
  if (!gridRef) return;
  const columnState = gridRef.columnApi.getColumnState();
  const filterState = gridRef.api.getFilterModel();
  setLocalStorageValue(table + '-columState', columnState);
  setLocalStorageValue(table + '-filterState', filterState);
  message.success('설정을 저장했습니다.');
}

export async function loadGridState(gridRef: AgGridReact | null, table: string) {
  if (!gridRef) return;
  const columState = getLocalStorageValue<ColumnState[]>(table + '-columState');
  const filterState = getLocalStorageValue(table + '-filterState');
  gridRef.columnApi.applyColumnState({ state: columState, defaultState: { sort: null }, applyOrder: true });
  gridRef.api.setFilterModel(filterState);
  message.success('설정을 불러왔습니다.');
}

export function resetGridState(gridRef: AgGridReact | null, table: string) {
  if (!gridRef) return;
  gridRef.columnApi.resetColumnState();
  gridRef.api.setFilterModel(null);
  saveGridState(gridRef, table);
  message.success('설정을 초기화했습니다.');
}

export const arrayValueFormatter = ({ value }: any) => (Array.isArray(value) ? value.join(', ') : '');

/**
 * 눌린키가 'Enter'이며 현재 편집중인 셀이면 true를 반환하여 편집 종료를 막는다.
 */
export const suppressEnterKeyEvent = (params: any) => {
  const key = params.event.key;
  return key === 'Enter' && params.editing;
};

export async function onCellDataDeleted<T>(params: NewValueParams<T>, callback: () => Promise<string | void>) {
  const {
    oldValue,
    newValue,
    colDef: { headerName },
  } = params;
  if (oldValue !== newValue && newValue === null) {
    try {
      await callback();
      message.success(`${headerName}을(를) 삭제 완료`);
    } catch (error: any) {
      message.error(error.message);
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////
//
// 발주 관리 관련 함수
//
///////////////////////////////////////////////////////////////////////////////////////////////

/**
 * 상품 관리에서 상품의 매입처 정보 변경
 */
export const onSelectValueChangeForProductSupplier = async (
  supplierId: string,
  product: Product,
  suppliers: SupplierDoc[],
  oldSuppliers: string[]
) => {
  const supplier = suppliers.find((s) => s._id === supplierId);
  if (!supplier) {
    throw new Error('매입처 정보를 찾을 수 없습니다.');
  }

  try {
    await setProductSupplier(oldSuppliers, product, supplier);
    message.success('변경되었습니다');
  } catch (error) {
    console.error(error);
    const msg = errorObjectToString(error);
    message.error(msg);
  }
};

/**
 * 상품의 매입처를 변경한다.
 */
export const productSupplierOnCellValueChanged = async (params: NewValueParams<ProductDoc>) => {
  const field = params.colDef.field;
  if (field !== 'suppliers') {
    message.error('수정할 수 없는 필드정보입니다.');
    return;
  }

  const value = params.data.suppliers;
  const oldSuppliers: string[] = params.oldValue;
  if (isEqual(value, oldSuppliers)) {
    message.info('변경된 정보가 없습니다.');
    return;
  }

  const supplierList = params.context.suppliers as SupplierDoc[];
  const supplier = supplierList.find((s) => s._id === value?.[0]);
  if (!supplier) {
    message.error('매입처 정보를 찾을 수 없습니다.');
    return;
  }

  try {
    await setProductSupplier(oldSuppliers, params.data, supplier);
    message.success('변경되었습니다');
    // re-focus after data update
    if (params?.node && params?.node?.rowIndex !== null) {
      await sleep(0);
      params.api.setFocusedCell(params?.node?.rowIndex, field);
    }
  } catch (error) {
    console.error(error);
    const msg = errorObjectToString(error);
    message.error(msg);
    // rollback
    if (params.node) {
      (params.data as any)[field] = params.oldValue;
      params.api.refreshCells({ rowNodes: [params.node], force: true });
    }
  }
};

/**
 * 발주 규칙을 수정한다.
 * 상품 관리에서 사용한다.
 */
export const onCellValueChangedForProductSupplierConversionItem = async (params: NewValueParams) => {
  const data: { conversionItem?: SupplierPurchaseConversionItem } = params.data;
  const field = params.colDef.field;
  if (!field) return;

  try {
    if (!field?.includes('conversionItem')) {
      throw new Error('수정할 수 없는 필드정보입니다.');
    }

    const conversionItem = get(data, 'conversionItem');
    if (!conversionItem) {
      throw new Error('변경할 대상이 없습니다.');
    }

    const newConversionItemValue = get(data, field);
    const oldConversionItemValue = params.oldValue;
    if (isEqual(newConversionItemValue, oldConversionItemValue)) {
      message.info('변경된 정보가 없습니다.');
      return;
    }

    if (['conversionItem.purchaseMaxBundle', 'conversionItem.purchaseMultiplyUnit'].includes(field)) {
      const path = field.split('.')[1];
      const toNumber = Number(newConversionItemValue);
      if (isNaN(toNumber)) {
        throw new Error('숫자만 입력 가능합니다.');
      }
      set(conversionItem, path, toNumber);
    }

    const suppliers = get(data, 'suppliers');
    const supplierList = params.context.suppliers as SupplierDoc[];
    const supplier = supplierList.find((s) => s._id === suppliers?.[0]);
    if (!supplier) {
      throw new Error('매입처 정보를 찾을 수 없습니다.');
    }

    await updateSupplierConversionItem(supplier, conversionItem);
    message.success('변경되었습니다');
    // re-focus after data update
    if (params?.node && params?.node?.rowIndex !== null) {
      await sleep(0);
      params.api.setFocusedCell(params?.node?.rowIndex, field);
    }
  } catch (error) {
    console.error(error);
    const msg = errorObjectToString(error);
    message.error(msg);
    // rollback
    if (params.node) {
      (data as any)[field] = params.oldValue;
      params.api.refreshCells({ rowNodes: [params.node], force: true });
    }
  }
};

/**
 * 발주 규칙을 수정한다.
 * 매입처 관리에서 사용한다.
 */
export const onCellValueChangedForSupplierConversionItem = async (params: NewValueParams) => {
  const data = params.data as SupplierPurchaseConversionItem & { supplier: SupplierDoc };
  const field = params.colDef.field;
  if (!field) return;

  try {
    const { supplier, ...conversionItem } = data;
    if (!supplier) {
      throw new Error('매입처 정보를 찾을 수 없습니다.');
    }
    const newConversionItemValue = get(conversionItem, field);
    const oldConversionItemValue = params.oldValue;
    if (isEqual(newConversionItemValue, oldConversionItemValue)) {
      message.info('변경된 정보가 없습니다.');
      return;
    }

    if (['purchaseMaxBundle', 'purchaseMultiplyUnit'].includes(field)) {
      const path = field.split('.')[1];
      const toNumber = Number(newConversionItemValue);
      if (isNaN(toNumber)) {
        throw new Error('숫자만 입력 가능합니다.');
      }
      set(data, path, toNumber);
    }

    await updateSupplierConversionItem(supplier, conversionItem);
    message.success('변경되었습니다');
    // re-focus after data update
    if (params?.node && params?.node?.rowIndex !== null) {
      await sleep(0);
      params.api.setFocusedCell(params?.node?.rowIndex, field);
    }
  } catch (error) {
    console.error(error);
    const msg = errorObjectToString(error);
    message.error(msg);
    // rollback
    if (params.node) {
      (data as any)[field] = params.oldValue;
      params.api.refreshCells({ rowNodes: [params.node], force: true });
    }
  }
};

/**
 * 수정된 Cell의 값을 검증한다.
 * @param params {@link NewValueParams}
 * @param type 'string' | 'number' | 'tel' | 'natural'
 * @returns
 */
export const onCellValueValidation = (params: NewValueParams, type: 'string' | 'number' | 'tel' | 'natural') => {
  const { data, colDef, newValue } = params;
  const field = colDef.field;
  if (!field) {
    message.error('수정할 수 없는 필드정보입니다.');
    return false;
  }

  const validation = {
    string: newValue.length > 0,
    number: !isNaN(Number(newValue)),
    natural: !isNaN(Number(newValue)) && Number(newValue) > 0,
    tel: phoneNumberRegex.test(newValue),
  };

  if (!validation[type]) {
    message.error('값이 올바르지 않습니다.');
  } else {
    data[field] = newValue;
  }

  return validation[type];
};

/**
 * 매장 이슈의 row style을 반환한다.
 */
export const getStoreIssueRowStyle = (params: RowClassParams<StoreIssueDoc>): any => {
  const { status } = params.node.data ?? {};
  if (status === '보류') {
    return { backgroundColor: '#e06666' };
  }
  if (status === '입력') {
    return { backgroundColor: '#fff2cc' };
  }
  if (status === '완료') {
    return { backgroundColor: '#b7e1cd' };
  }
};

/**
 * 그리드에서 드래그가 끝난 후 호출된다.
 * @param field 순서 정보가 담긴 필드
 */
export const createOnRowDragEndHandler = (collection: string, field: string) => async (params: any) => {
  const gridApi = params?.api as GridApi;
  if (!gridApi) {
    notification.error({
      message: 'grid api를 찾을 수 없습니다.',
    });
    return;
  }

  const chagnedNodes: RowNode[] = [];
  gridApi.forEachNode((node) => {
    const { rowIndex, data } = node;
    // 1. 순서가 변경된 노드 즉 rowIndex + 1과 data.index가 다른 노드만 추출한다.
    if (rowIndex !== null && rowIndex + 1 !== data[field]) {
      chagnedNodes.push(node);
    }
  });

  if (!chagnedNodes || chagnedNodes?.length === 0) {
    return;
  }

  try {
    const updateList = chagnedNodes.map((node) => {
      const { rowIndex, data } = node;
      return { _id: data._id, [field]: (rowIndex ?? 999) + 1 };
    });
    firebaseManager.batchStart();
    for (const item of updateList) {
      await firebaseManager.updateDoc(
        collection,
        item._id,
        { [field]: item[field] },
        {
          bBatch: true,
        }
      );
    }
    await firebaseManager.batchEnd();
    message.success('순서를 변경했습니다.');
  } catch (error) {
    console.error(error);
    const description = errorObjectToString(error);
    notification.error({
      message: '순서 변경에 실패했습니다.',
      description,
    });
  }
};

/**
 * 결제내역의 상태에 맞는 cell class를 반환한다.
 */
export const getCellClassForPaymentStatus = (params: any) => {
  if (params.value === PaymentStatusCode.COMPLETED) {
    return 'green300';
  } else if (
    [PaymentStatusCode.FAILED, PaymentStatusCode.CANCELLED].includes(params.value as unknown as PaymentStatusCode)
  ) {
    return 'red400';
  }
  return null;
};
