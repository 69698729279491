import { AccessInfo } from '@gooduncles/gu-app-schema';
import { Button, Form, Input, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import AcccessInfoTag from 'src/atomic-components/atoms/AccessInfoTag/AccessInfoTag';

import styles from './KeyValueTagForm.module.scss';

const { Option } = Select;

interface KeyValueTagFormProps {
  initialKeyValueList: AccessInfo[];
  keyLabel: string;
  keyPlaceholder: string;
  valueLabel: string;
  valuePlaceholder: string;
  options: { key: string; label: string }[];
  loading: boolean;
  onTagsChange: (tags: AccessInfo[]) => void;
  onClose?: () => void;
}

const KeyValueTagForm: FC<KeyValueTagFormProps> = ({
  initialKeyValueList,
  keyLabel,
  keyPlaceholder,
  valueLabel,
  valuePlaceholder,
  options,
  loading,
  onTagsChange,
  onClose,
}) => {
  const [keyValueList, setKeyValueList] = useState<AccessInfo[]>(initialKeyValueList);
  const [selectedKey, setSelectedKey] = useState<string | undefined>();
  const [form] = Form.useForm();

  const handleAddTag = () => {
    form.validateFields().then((values) => {
      const { key, value } = values;
      if (key) {
        setKeyValueList([...keyValueList, { method: key, details: value }]);

        form.resetFields(['value']);
      }
    });
  };

  const handleRemoveTag = (index: number) => {
    setKeyValueList((prevList) => {
      return prevList.filter((_, i) => i !== index);
    });
  };

  const handleClose = () => {
    form.resetFields();
    setSelectedKey(undefined);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    onTagsChange(keyValueList);
  }, [keyValueList, onTagsChange]);

  return (
    <Form layout='vertical' form={form} className={styles.keyValueTagForm}>
      {/* Display Tags */}
      <div className={styles.tags}>
        {keyValueList.length === 0 ? (
          <div className={styles.emptyMessage}>태그를 추가하려면 Key와 Value를 입력하세요.</div>
        ) : (
          keyValueList.map((item, index) => (
            <AcccessInfoTag key={uuidv4()} accessInfo={item} onClose={() => handleRemoveTag(index)} />
          ))
        )}
      </div>

      {/* Select for Key */}
      <Form.Item label={keyLabel} name='key' rules={[{ required: true, message: 'Please select a key' }]}>
        <Select
          placeholder={keyPlaceholder}
          value={selectedKey}
          onChange={(value) => setSelectedKey(value)}
          size='large'
          style={{ width: '100%' }}>
          {options.map((option) => (
            <Option key={option.key} value={option.key}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {/* Input for Value */}
      <Form.Item label={valueLabel} name='value'>
        <Input size='large' placeholder={valuePlaceholder} />
      </Form.Item>

      {/* Action Buttons */}
      <Form.Item>
        <div className={styles.actionButtons}>
          {onClose && (
            <Button type='default' size='large' onClick={handleClose} className={styles.button} loading={loading}>
              닫기
            </Button>
          )}
          <Button type='primary' size='large' onClick={handleAddTag} className={styles.button} loading={loading}>
            입력
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default KeyValueTagForm;
