import { FC } from 'react';

import { formatNumber } from 'src/lib/1/util';

import useMonthlyProductStats from 'src/hooks/useMonthlyProductStats';

interface MonthlyProductSalesCellRendererProps {
  productId: string;
}

const MonthlyProductSalesCellRenderer: FC<MonthlyProductSalesCellRendererProps> = ({ productId }) => {
  const { monthlyProductStats } = useMonthlyProductStats(productId);
  const volume = monthlyProductStats.reduce((acc, cur) => acc + cur.totalVolume, 0) ?? 0;

  return <span>{formatNumber(volume, 1)}</span>;
};

export default MonthlyProductSalesCellRenderer;
