import { DeliveryTask, OrderStatusCode, TaskOrderCollection } from '@gooduncles/gu-app-schema';
import { Button, Tabs, message, notification } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { FC, useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { finishPickupTask } from 'src/utils/task-util';

import { formatDate, getDefaultDate } from 'src/lib/1/date-util';
import { errorObjectToString, promiseAllSettled } from 'src/lib/1/util';
import {
  batchEnd,
  batchStart,
  getOrder,
  getStoreIssue,
  updateDeliveryTask,
  updateOrder,
  updateStoreIssue,
} from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';

import { selectPartnersUsers } from 'src/redux/slices/partners';
import { useAppSelector } from 'src/redux/store';

import { useTitle } from 'src/hooks/useTitle';

import DatePickerWithArrows from 'src/components/Common/DatePickerWithArrows/DatePickerWithArrows';
import Loading from 'src/components/Loading/Loading';

import DeliveryTaskTable, { DeliveryTaskRowData } from './DeliveryTaskTable';
import PickupTaskTable, { PickupTaskRowData } from './PickupTaskTable';

const logger = ConsoleLogger.getInstance();
const logName = '물류 업무 히스토리';
const defaultValue = getDefaultDate();
/**
 * 미래의 날짜를 선택하지 못하도록 하는 함수
 */
const disableFutureDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current > dayjs().add(1, 'day').endOf('day');
};

/**
 * 픽업 업무를 완료처리합니다.
 */
const finishSelectedPickupTasks = async (pickupTasks: PickupTaskRowData[]) => {
  if (pickupTasks.length === 0) {
    message.error('완료 처리할 픽업 업무를 선택해주세요.');
    return;
  }

  try {
    logger.logConsole(`[${logName}] ${pickupTasks.length}건의 픽업 업무 완료 시도`);
    batchStart();
    for (const pickupTask of pickupTasks) {
      await finishPickupTask(pickupTask);
    }
    await batchEnd();
    notification.success({
      message: '픽업 업무 완료 처리 완료',
      description: `${pickupTasks.length}건을 완료 처리했습니다.`,
    });
    logger.logConsole(`[${logName}] ${pickupTasks.length}건의 픽업 업무 완료 성공 ✅`);
  } catch (error) {
    console.error(error);
    const errorMsg = errorObjectToString(error);
    message.error(`픽업 업무 완료 처리에 실패했습니다. ${errorMsg}`);
    logger.logConsole(`[${logName}] ${pickupTasks.length}건의 픽업 업무 완료 실패 ❌`);
  }
};

/**
 * 배송 업무를 완료 처리합니다.
 */
const finishSelectedDeliveryTasks = async (deliveryTasks: DeliveryTaskRowData[]) => {
  if (deliveryTasks.length === 0) {
    message.error('완료 처리할 배송 업무를 선택해주세요.');
    return;
  }

  try {
    logger.logConsole(`[${logName}] ${deliveryTasks.length}건의 배송 업무 완료 시도`);
    // 1. 완료할 주문 목록을 가져온다.
    const orderPromises = deliveryTasks
      .filter((task) => task.orderColleciton === TaskOrderCollection.Order)
      .map(async (task) => getOrder(task.orderId));
    const storeIssuePromises = deliveryTasks
      .filter((task) => task.orderColleciton === TaskOrderCollection.StoreIssue)
      .map(async (task) => getStoreIssue(task.orderId));
    const { fulfilled: fulfilledOrders, rejected: rejectedOrders } = await promiseAllSettled(orderPromises);
    const { fulfilled: fulfilledIssues, rejected: rejectedIssues } = await promiseAllSettled(storeIssuePromises);
    // 2. 불러오지 못한 주문이 있으면 중지한다.
    if ([...rejectedOrders, ...rejectedIssues].length > 0) {
      throw new Error([...rejectedOrders, ...rejectedIssues].join('\n'));
    }

    // 3. 가져온 주문 혹은 이슈에서 문제가 없는지 확인한다.
    const invalidOrders = fulfilledOrders.filter((order) => order?.orderStatus !== OrderStatusCode.ACCEPTED);
    const invalidIssues = fulfilledIssues.filter((issue) => issue?.isDeleted === true || issue?.status !== '입력');
    if (invalidIssues.length > 0 || invalidOrders.length > 0) {
      throw new Error(
        `완료할 수 없는 주문이 포함되어 있습니다.\n${invalidOrders
          .map((order) => `주문: ${order?._id}`)
          .join('\n')}\n${invalidIssues.map((issue) => `이슈: ${issue?._id}`).join('\n')}`
      );
    }

    // 4. 완료 처리를 시작한다.
    const finishedAt = formatDate(Timestamp.now().toDate(), "yyyy-MM-dd'T'HH:mm:ss+0900");
    const updateData: Partial<DeliveryTask> = {
      finishedAt,
    };
    batchStart();
    for (const deliveryTask of deliveryTasks) {
      if (deliveryTask.orderColleciton === TaskOrderCollection.Order) {
        await updateOrder(deliveryTask.orderId, {
          deliveryTaskFinishedAt: finishedAt,
          orderStatus: OrderStatusCode.DELIVERED,
        });
      } else if (deliveryTask.orderColleciton === TaskOrderCollection.StoreIssue) {
        await updateStoreIssue(
          deliveryTask.orderId,
          {
            deliveryTaskFinishedAt: finishedAt,
            status: '완료',
          },
          true
        );
      } else {
        throw new Error('연결된 주문 정보를 찾을 수 없습니다.');
      }
      await updateDeliveryTask(deliveryTask._id, updateData, true);
    }
    await batchEnd();
    logger.logConsole(
      `[${logName}] ${deliveryTasks.length}건의 배송 업무 완료 & 관련 주문(이슈)의 상태 업데이트 성공 ✅`
    );
  } catch (error) {
    console.error(error);
    const errorMsg = errorObjectToString(error);
    message.error(`배송 업무 완료 처리에 실패했습니다. ${errorMsg}`);
    logger.logConsole(`[${logName}] ${deliveryTasks.length}건의 배송 업무 완료 실패 ❌`);
  }
};

const TaskHistoryPage: FC = () => {
  useTitle('GU 관리자 | 물류 업무 히스토리');
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? 'delivery';
  const [datePickerValue, setDatePickerValue] = useState<dayjs.Dayjs | null>(defaultValue);
  const date = useMemo(
    () => (datePickerValue ? formatDate(datePickerValue.toDate(), 'yyyy-MM-dd') : ''),
    [datePickerValue]
  );
  const partnersUsers = useAppSelector(selectPartnersUsers);
  const [selectedDeliveryTaskRows, setSelectDeliveryTaskRows] = useState<DeliveryTaskRowData[]>([]);
  const [selectedPickupTaskRows, setSelectPickupTaskRows] = useState<PickupTaskRowData[]>([]);

  const onFinishSelectedDeliveryTasks = () => {
    setIsLoading(true);
    finishSelectedDeliveryTasks(selectedDeliveryTaskRows).finally(() => setIsLoading(false));
  };

  const onFinishSelectedPickupTasks = () => {
    setIsLoading(true);
    finishSelectedPickupTasks(selectedPickupTaskRows).finally(() => setIsLoading(false));
  };

  const onTabChange = useCallback(
    (tab: string) => {
      setSearchParams({ tab });
    },
    [setSearchParams]
  );

  return (
    <div className='height100 flexColumn'>
      {isLoading && <Loading title='처리중입니다.' />}
      <div className='flexRow flexAlignCenter flexSpaceBetween'>
        <DatePickerWithArrows date={datePickerValue} setDate={setDatePickerValue} disabledDate={disableFutureDate} />
        <div>
          {tab === 'delivery' && (
            <Button
              disabled={selectedDeliveryTaskRows.length === 0}
              onClick={onFinishSelectedDeliveryTasks}
              loading={isLoading}>
              {selectedDeliveryTaskRows.length}건의 배송 업무 완료 처리
            </Button>
          )}
          {tab === 'pickup' && (
            <Button
              disabled={selectedPickupTaskRows.length === 0}
              onClick={onFinishSelectedPickupTasks}
              loading={isLoading}>
              {selectedPickupTaskRows.length}건의 픽업 업무 완료 처리
            </Button>
          )}
        </div>
      </div>
      <Tabs
        type='card'
        defaultActiveKey={tab}
        style={{ height: '100%', overflow: 'scroll' }}
        tabBarStyle={{ marginBottom: 0 }}
        onTabClick={onTabChange}
        items={[
          {
            key: 'delivery',
            label: '배송 업무',
            children: (
              <DeliveryTaskTable
                date={date}
                partnersUsers={partnersUsers}
                setSelectedRows={setSelectDeliveryTaskRows}
              />
            ),
          },
          {
            key: 'pickup',
            label: '픽업 업무',
            children: (
              <PickupTaskTable date={date} partnersUsers={partnersUsers} setSelectedRows={setSelectPickupTaskRows} />
            ),
          },
        ]}
      />
    </div>
  );
};

export default TaskHistoryPage;
