import { PushMessagePayload } from '@gooduncles/gu-app-schema';
import { notification } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from 'src/stores/auth-context';

import { errorObjectToString } from 'src/lib/1/util';

import usePushForPartnerByAdmin from '../../hooks/usePushForPartnerByAdmin';

import classes from './ChatRoom.module.scss';

import { createPushMessageForPartner } from '../../utils/chat-util';
import ChatMessageBubble from '../Common/ChatMessageBubble';

const ChatRoom: React.FC = () => {
  const { user } = useAuth();
  const { id: partnerId } = useParams<{ id: string }>();
  const [newMessage, setNewMessage] = useState<string>('');
  const chatMessages = usePushForPartnerByAdmin(user?._id, partnerId);

  const sendMessage = async () => {
    const trimmedMessage = newMessage.trim();
    if (!user || !trimmedMessage || !partnerId) {
      return;
    }
    try {
      const messagePayload: PushMessagePayload = {
        title: user.email,
        body: trimmedMessage,
        imageUrl: null,
        type: 'chat',
        actionType: null,
        actionValue: null,
      };
      await createPushMessageForPartner(messagePayload, user._id, partnerId);
      setNewMessage(''); // 입력 필드 초기화
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({ message: '메시지 전송 실패', description });
    }
  };

  return (
    <div className={classes.chatRoom}>
      <h2>채팅방 </h2>
      <div className={classes.messages}>
        {chatMessages.map((msg) => (
          <ChatMessageBubble key={msg.id} message={msg} />
        ))}
      </div>
      <div className={classes.messageInput}>
        <input
          type='text'
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder='메시지 입력'
        />
        <button onClick={sendMessage}>전송</button>
      </div>
    </div>
  );
};

export default ChatRoom;
