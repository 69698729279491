import { OrderDoc, OrderStatusCode, StoreDoc, StoreIssueDoc } from '@gooduncles/gu-app-schema';
import { DeliveryRouteHistoryDoc, DeliverySpotDoc } from '@gooduncles/gu-app-schema';
import { groupBy } from 'lodash-es';
import { DeliveryItem, PackingSheet } from 'src/schema/schema-packing-sheet';

import { commerceIssueCategoryTable, packingOrder, storageOrder } from 'src/lib/1/constant';

/**
 * 주문과 이슈를 매장으로 묶어서 배송 건을 생성한다.
 */
export const generateDeliveryItemsForStore = (stores: StoreDoc[], orders: OrderDoc[], storeIssues: StoreIssueDoc[]) => {
  const storeIssuesByStore = groupBy(storeIssues, 'storeCode');

  const deliveryItemsForStore = stores
    .map((store) => {
      /**
       * 수락 주문은 날짜와 상관없이 불러오기 때문에 시점이 과거인경우 오늘의 수락 주문과 배송완료 주문이 모두 존재할 수 있다.
       * 때문에 배송 완료 주문이 존재하면 수락 주문은 무시한다.
       */
      const acceptedOrder = orders.find(
        (order) => order.storeId === store._id && order.orderStatus === OrderStatusCode.ACCEPTED
      );
      const deliveredOrder = orders.find(
        (order) => order.storeId === store._id && order.orderStatus === OrderStatusCode.DELIVERED
      );
      const storeIssues = storeIssuesByStore[store.storeCode ?? ''] ?? [];
      return {
        deliverySpotId: store.deliverySpotId,
        storeId: store._id,
        store,
        order: deliveredOrder ?? acceptedOrder,
        storeIssues,
      };
    })
    // 주문 또는 이슈가 있는 아이템만 필터링한다.
    .filter((item) => item.order !== undefined || item.storeIssues.length > 0);
  return deliveryItemsForStore;
};

/**
 * 배송 동선과 주문(또는 이슈)를 그룹핑한다.
 * - 단 배송 동선이 미설정된 경우(담당자의 실수 혹은 배송완료후 추가된 이슈 등) 미할당(동선X)으로 그룹핑한다.
 */
export const groupingDeliveryItemsByPartner = (
  deliverySpots: DeliverySpotDoc[],
  deliveryItemsForStore: {
    deliverySpotId: string;
    storeId: string;
    store: StoreDoc;
    order: OrderDoc | undefined;
    storeIssues: StoreIssueDoc[];
  }[]
) => {
  // 배송 동선에 기록되지 않은 주문
  const unassignedDeliveryItems = deliveryItemsForStore.filter(
    (item) => deliverySpots.find((s) => s._id === item.deliverySpotId) === undefined
  );
  const unassignedDeliveryGroup =
    unassignedDeliveryItems.length > 0
      ? [
          {
            _id: 'undefined',
            partnerId: 'undefined',
            title: '미할당(동선X)',
            sortKey: 0,
            roadAddress: '',
            jibunAddress: '',
            lat: 0,
            lng: 0,
            isDeleted: false,
            deliveryStoresWithItems: unassignedDeliveryItems,
          },
        ]
      : [];
  const groupByPartnerId = groupBy(
    [
      ...deliverySpots.map((deliverySpot) => {
        // 배송 지점에 속한 매장
        const deliveryStoresWithItems = deliveryItemsForStore.filter(
          (item) => item.deliverySpotId === deliverySpot._id
        );
        return {
          ...deliverySpot,
          deliveryStoresWithItems,
        };
      }),
      ...unassignedDeliveryGroup,
    ],
    'partnerId'
  );
  return groupByPartnerId;
};

/**
 * 일일 배송 동선 목록을 바탕으로 패킹시트를 생성한다.
 */
export const generatePackingSheets = (
  deliveryRouteHistory: DeliveryRouteHistoryDoc,
  stores: StoreDoc[],
  orders: OrderDoc[],
  storeIssues: StoreIssueDoc[]
) => {
  const deliveryItemsForStore = generateDeliveryItemsForStore(stores, orders, storeIssues);
  const { deliverySpots } = deliveryRouteHistory;
  const groupByPartnerId = groupingDeliveryItemsByPartner(deliverySpots, deliveryItemsForStore);

  const packingSheets = Object.fromEntries(
    Object.entries(groupByPartnerId).map(([partnerId, spots]) => {
      const deliverySpotsForSheet: PackingSheet['deliverySpots'] = spots.map((spot) => {
        return {
          deliverySpotId: spot._id,
          title: spot.title,
          stores: spot.deliveryStoresWithItems.map((deliveryStoreWithItems) => {
            const orderItems: DeliveryItem[] =
              deliveryStoreWithItems.order?.products
                .sort((a, b) => storageOrder.indexOf(a.storage) - storageOrder.indexOf(b.storage))
                .sort((a, b) => packingOrder.indexOf(a.packing) - packingOrder.indexOf(b.packing))
                .map((product) => ({
                  label: product.fullName,
                  quantity: product.volume,
                  placement: product.packing === 'bag' ? 'indoors' : 'outdoors',
                  originPath: `order/${deliveryStoreWithItems.order?._id}`,
                  storage: product.storage,
                  productFeature: product.packingInfo,
                  storeFeature: null,
                })) ?? [];

            const storeIssueItems: DeliveryItem[] = deliveryStoreWithItems.storeIssues
              .filter((issue) => ['배송', '회수'].includes(issue.category ?? ''))
              .map((storeIssue) => {
                const prefix = storeIssue.category ? commerceIssueCategoryTable[storeIssue.category] ?? '' : '';
                const fullName = storeIssue.message ? prefix + storeIssue.message : storeIssue.memo;
                return {
                  label: fullName ?? '?',
                  quantity: storeIssue.volume ?? 0,
                  placement: 'outdoors',
                  originPath: `storeIssue/${storeIssue._id}`,
                  storage: null,
                  productFeature: null,
                  storeFeature: null,
                };
              });
            return {
              storeId: deliveryStoreWithItems.storeId,
              storeName: deliveryStoreWithItems.store.storeNickname,
              deliveryItems: [...orderItems, ...storeIssueItems],
            };
          }),
        };
      });
      return [
        partnerId,
        {
          partnerId,
          deliverySpots: deliverySpotsForSheet,
        },
      ];
    })
  );

  return packingSheets;
};
