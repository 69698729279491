import { FC } from 'react';

import { timeToFamiliarNotation } from 'src/lib/1/date-util';

import classes from './ChatMessageBubble.module.scss';

import { ChatMessage } from '../../schema/chat';

interface ChatMessageProps {
  message: ChatMessage;
}

const ChatMessageBubble: FC<ChatMessageProps> = ({ message }) => {
  const time = timeToFamiliarNotation(new Date(message.timestamp));
  return (
    <div className={`${classes.messageContainer} ${classes[message.type]}`}>
      <strong>{message.title}</strong>
      <br />
      <div className={classes.messageBubble}>
        <span>{message.content}</span>
        <br />
        <small>{time}</small>
      </div>
    </div>
  );
};

export default ChatMessageBubble;
