import useSWR from 'swr';

import { getLogOrdersByOrderId } from 'src/lib/4/firebase-short-cut';

const fetcher = async (request: [string, string]) => {
  const [orderId] = request;
  return getLogOrdersByOrderId(orderId);
};

const useLogOrdersByOrderId = (id: string | undefined) => {
  const {
    data,
    isValidating: logOrdersLoading,
    error: logOrdersError,
  } = useSWR(id ? [id, 'log-orders'] : null, fetcher, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });

  return {
    logOrders: data,
    logOrdersLoading,
    logOrdersError,
  };
};

export default useLogOrdersByOrderId;
