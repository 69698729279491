import dayjs from 'dayjs';
import { FC, useMemo, useState } from 'react';

import { disableFutureDate, formatDate, getDefaultDate } from 'src/lib/1/date-util';

import { useTitle } from 'src/hooks/useTitle';

import DailyCheckListBoard from 'src/atomic-components/organisms/DailyCheckListBoard/DailyCheckListBoard';
import DatePickerWithArrows from 'src/components/Common/DatePickerWithArrows/DatePickerWithArrows';

const defaultValue = getDefaultDate();

const DashboardPage: FC = () => {
  const [date, setDate] = useState<dayjs.Dayjs | null>(defaultValue);
  const formattedDate = useMemo(() => (date ? formatDate(date.toDate(), 'yyyy-MM-dd') : ''), [date]);
  useTitle('GU 관리자 | 대시보드');

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20, height: '100%' }}>
      <DatePickerWithArrows date={date} setDate={setDate} disabledDate={disableFutureDate} />
      <DailyCheckListBoard date={formattedDate} />
    </div>
  );
};

export default DashboardPage;
