import { OrderDoc, OrderStatusCode } from '@gooduncles/gu-app-schema';
import { Checkbox, Tabs } from 'antd';
import { differenceInDays } from 'date-fns';
import { sumBy, uniqBy } from 'lodash-es';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { useAuth } from 'src/stores/auth-context';

import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { orderStatusKr } from 'src/lib/1/constant';
import { RangeEventValue, dayjsesToStringDateWhere, getDefaultDates } from 'src/lib/1/date-util';
import { formatNumber } from 'src/lib/1/util';
import { FirebaseManager, WHERE } from 'src/lib/3/firebase-manager';
import { observeStore } from 'src/lib/4/firebase-short-cut';
import { OrderWithStore } from 'src/lib/5/order-util';

import useCommerceConf from 'src/hooks/useCommerceConf';
import useSuppliers from 'src/hooks/useSuppliers';

import classes from './Order.module.scss';

import TableOptions from '../Common/TableOptions/TableOptions';
import EditOrderModal from '../Modal/EditOrderModal/EditOrderModal';
import OrderProductTable from './OrderProductTable/OrderProductTable';
import OrderTable from './OrderTable/OrderTable';
import RawDataDownloadButton from './RawDataDownloadButton/RawDataDownloadButton';

const defaultValue = getDefaultDates(7);

const firebaseManager = FirebaseManager.getInstance();
const ordrePath = 'order';

const orderStatusOption = Object.entries(orderStatusKr).map(([value, label]) => ({
  label: label.slice(2),
  value: Number(value),
}));

const getSummary = (orders: OrderWithStore[]) => {
  // 총 금액
  const totalAmountSum = sumBy(orders, (order) => order.totalAmount);
  // 총 금액(배송비 포함)
  const paidAmountSum = sumBy(orders, (order) => order.paidAmount);
  // 주문수
  const orderSum = orders.length;
  // 배송비
  const deliveryFee = sumBy(orders, (order) => order.deliveryFee ?? 0);
  // 품목수
  const uniqueProducts = uniqBy(orders.map((o) => o.products).flat(), 'productId');
  const productsSum = uniqueProducts.length;
  // 전체 동기화 여부
  const isAllSynced = orders
    .map((o) => o.products)
    .flat()
    .every((product) => product.priceDiff !== null);
  const data: any = {
    _id: 'summary',
    totalAmount: totalAmountSum,
    productCount: productsSum,
    orderStatus: `총 주문수: ${formatNumber(orderSum)}`,
    products: [],
    isAllSynced,
    paidAmount: paidAmountSum,
    deliveryFee,
  };
  return data;
};

const Order: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get('tab');

  const { user } = useAuth();
  const { commerceConf } = useCommerceConf();
  const [selectedStatus, setSelectedStatus] = useState([OrderStatusCode.SUBMITTED, OrderStatusCode.ACCEPTED]);
  const [dates, setDates] = useState<RangeEventValue>(null);
  const [ordersWithStore, setOrdersWithStore] = useState<OrderWithStore[]>([]);
  const [leftDate, rightDate] = useMemo(() => {
    return dates?.[0] && dates?.[1]
      ? dayjsesToStringDateWhere(dates[0], dates[1])
      : dayjsesToStringDateWhere(defaultValue[0], defaultValue[1]);
  }, [dates]);
  const [summary, setSummary] = useState<OrderWithStore>();
  const suppliers = useSuppliers();
  const disabledRawDataDownload = useMemo(() => {
    if (dates?.[0] && dates?.[1]) {
      const diffDays = differenceInDays(dates[1]?.toDate(), dates[0].toDate());
      return diffDays > 7;
    }
    return false;
  }, [dates]);

  const onSelectedStatusChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedStatus(checkedValues as number[]);
  };

  // 주문 수정 및 생성을 하려면 중복 확인을 위해 아래의 조건을 만족해야 한다.
  const isOrderEditable = useMemo(
    () => [OrderStatusCode.SUBMITTED, OrderStatusCode.ACCEPTED].every((status) => selectedStatus.includes(status)),
    [selectedStatus]
  );

  useEffect(() => {
    const storeObervable = observeStore();
    const wheres: WHERE[] = selectedStatus.length > 0 ? [['orderStatus', 'in', selectedStatus]] : [];
    const ordersObervable = firebaseManager.observeCollection<OrderDoc>(ordrePath, wheres, {
      sortKey: 'orderDate',
      orderBy: 'desc',
      startValue: leftDate,
      endValue: rightDate,
    });
    const subscription = combineLatest([storeObervable, ordersObervable]).subscribe(([stores, orders]) => {
      const ordersWithStore: OrderWithStore[] = orders.map((order) => {
        const store = stores.find((store) => store._id === order.storeId);
        return { ...order, store, productCount: order.products.length };
      });
      setOrdersWithStore(ordersWithStore);
      setSummary(getSummary(ordersWithStore));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [leftDate, selectedStatus, rightDate]);

  return (
    <div className={classes.orderContainer}>
      <TableOptions setDates={setDates} defaultValue={defaultValue}>
        {user && <EditOrderModal ordersWithStore={ordersWithStore} disabled={!isOrderEditable} />}
        <Checkbox.Group
          style={{ marginRight: 10 }}
          options={orderStatusOption}
          value={selectedStatus}
          onChange={onSelectedStatusChange}
        />
        <RawDataDownloadButton
          orders={ordersWithStore}
          selectedStatus={selectedStatus}
          commerceConf={commerceConf}
          suppliers={suppliers}
          disabled={disabledRawDataDownload}
        />
      </TableOptions>
      <Tabs
        type='card'
        defaultActiveKey={currentTab ?? 'order'}
        style={{ height: '100%', overflow: 'scroll' }}
        tabBarStyle={{ marginBottom: 0 }}
        onTabClick={(key) => setSearchParams({ tab: key })}
        items={[
          {
            key: 'order',
            label: '주문별 보기',
            children: <OrderTable rowData={ordersWithStore} summary={summary} />,
          },
          {
            key: 'product',
            label: '상품별 보기',
            children: <OrderProductTable rowData={ordersWithStore} suppliers={suppliers} />,
          },
        ]}
      />
    </div>
  );
};

export default Order;
