import { PaperInvoice } from '@gooduncles/gu-app-schema';
import { FC, useMemo } from 'react';

import { packingOrder, storageOrder } from 'src/lib/1/constant';
import { formatNumber } from 'src/lib/1/util';
import { calculatePreTaxPrice } from 'src/lib/5/order-util';

import useBusinessInfo from 'src/hooks/useBusinessInfo';

import classes from './PaperInvoiceView.module.scss';

interface PaperInvoiceViewProps {
  paperInvoice: PaperInvoice;
}

const numberOrNull = (num: number | null) => {
  if (num === null) {
    return '-';
  }
  return formatNumber(num);
};

const PaperInvoiceHeader: FC<{
  target: string;
  businessName: string;
  businessNumber: string;
  businessAddress: string;
  ownerName: string;
}> = ({ target, businessName, businessNumber, businessAddress, ownerName }) => {
  return (
    <div className={classes.headerBox}>
      <div className={classes.target}>{target}</div>
      <div className={classes.businessInfo}>
        <div className={classes.gridItem}>
          <h3>상호</h3>
          <p>{businessName}</p>
        </div>
        <div className={classes.gridItem}>
          <h3>등록번호</h3>
          <div className={classes.flexItem}>
            <p>{businessNumber}</p>
            <p>{ownerName}</p>
          </div>
        </div>
        <div className={classes.gridItem}>
          <h3>주소</h3>
          <p>{businessAddress}</p>
        </div>
      </div>
    </div>
  );
};

const PaperInvoiceView: FC<PaperInvoiceViewProps> = ({ paperInvoice }) => {
  const { date, store, order, storeIssues } = paperInvoice;
  const { businessInfo } = useBusinessInfo();

  const deliveryItems = useMemo(() => {
    const orderProducts =
      order?.products
        .sort((a, b) => storageOrder.indexOf(a.storage) - storageOrder.indexOf(b.storage))
        .sort((a, b) => packingOrder.indexOf(a.packing) - packingOrder.indexOf(b.packing))
        .map((p) => {
          const productPrice = p.snapshotPrice ?? p.price;
          const { price, tax } = calculatePreTaxPrice(productPrice, p.taxFree);
          return {
            name: p.fullName,
            quantity: p.volume,
            cost: price,
            supplyPrice: price * p.volume,
            tax: tax * p.volume,
            price: productPrice * p.volume,
          };
        }) ?? [];

    if (order?.deliveryFee && order?.deliveryFee > 0) {
      orderProducts.push({
        name: '배송비',
        quantity: 1,
        cost: order.deliveryFee,
        supplyPrice: order.deliveryFee,
        tax: 0,
        price: order.deliveryFee,
      });
    }

    const storeIssueProducts = storeIssues.map((issue) => {
      return {
        name: issue.message ?? issue.productName,
        quantity: issue.volume,
        cost: null,
        supplyPrice: issue.supplyPrice,
        tax: issue.tax,
        price: issue.supplyPrice ? (issue.tax ?? 0) + issue.supplyPrice : null,
      };
    });

    return [...orderProducts, ...storeIssueProducts];
  }, [order?.deliveryFee, order?.products, storeIssues]);

  if (!businessInfo) {
    return <div>Loading..</div>;
  }

  return (
    <div className={classes.invoiceContainer}>
      <h1>{date} 식자재 배송내역</h1>
      <header className={classes.invoiceHeader}>
        <PaperInvoiceHeader
          target='공급받는분'
          businessName={store.storeNickname ?? '미정'}
          businessNumber={store.businessNumber ?? '미정'}
          businessAddress={store.businessAddress ?? '미정'}
          ownerName={store.ownerName ?? '미정'}
        />
        <PaperInvoiceHeader
          target='공급자'
          businessName={businessInfo.businessName}
          businessNumber={businessInfo.businessNumber}
          businessAddress={businessInfo.businessAddress}
          ownerName={businessInfo.ownerName}
        />
      </header>
      <div className={classes.summary}>
        <div className={classes.title}>오늘의 주문 총 금액</div>
        <div>{numberOrNull(deliveryItems.reduce((acc, i) => acc + (i.supplyPrice ?? 0), 0))}</div>
        <div>{numberOrNull(deliveryItems.reduce((acc, i) => acc + (i.tax ?? 0), 0))}</div>
        <div>{numberOrNull(deliveryItems.reduce((acc, i) => acc + (i.price ?? 0), 0))}</div>
      </div>
      <div className={classes.detail}>
        <div className={classes.detailHeader}>
          <div>No</div>
          <div>품명</div>
          <div>수량</div>
          <div>단가</div>
          <div>공급가</div>
          <div>세액</div>
          <div>합계</div>
        </div>
        <div className={classes.detailBody}>
          {deliveryItems.map((item, i) => (
            <div className={classes.detailBodyRow} key={i}>
              <div>{i + 1}</div>
              <div>{item.name}</div>
              <div className={classes.number}>{numberOrNull(item.quantity)}</div>
              <div className={classes.number}>{numberOrNull(item.cost)}</div>
              <div className={classes.number}>{numberOrNull(item.supplyPrice)}</div>
              <div className={classes.number}>{numberOrNull(item.tax)}</div>
              <div className={classes.number}>{numberOrNull(item.price)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PaperInvoiceView;
