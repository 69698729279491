import { AccessMethod, AccessMethods } from '@gooduncles/gu-app-schema';
import { FC } from 'react';

import card from 'src/assets/images/card-200.png';
import fingerprint from 'src/assets/images/fingerprint-200.png';
import key from 'src/assets/images/key-200.png';
import noKey from 'src/assets/images/no-key-200.png';
import numberKey from 'src/assets/images/number-key-200.png';
import passwordLock from 'src/assets/images/password-lock-200.png';
import secretKey from 'src/assets/images/secret-key-200.png';

import classes from './AccessMethodGroup.module.scss';

const accessMethodImageMap: Record<AccessMethod, string> = {
  cardKey: card,
  key: key,
  keypad: numberKey,
  codeLock: passwordLock,
  hiddenKey: secretKey,
  fingerprint: fingerprint,
  noKey: noKey,
};

interface AccessMethodGroupProps {
  method: AccessMethod;
  stores: string[];
}

const AccessMethodGroup: FC<AccessMethodGroupProps> = ({ method, stores }) => {
  return (
    <div className={classes.accessMethodGroup}>
      <div className={classes.accessMethod}>
        <img className={classes.accessMethodImg} src={accessMethodImageMap[method]} />
        <span className={classes.accessMethodTitle}>{AccessMethods[method]}</span>
      </div>
      {stores.map((store, idx) => (
        <p key={idx} className={classes.accessMethodStore}>
          • {store}
        </p>
      ))}
    </div>
  );
};

export default AccessMethodGroup;
