import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import ChatRoom from '../components/ChatRoom/ChatRoom';

const ChatRoutes: FC = () => {
  return (
    <Routes>
      <Route path='/' element={<div>Chat Home</div>}></Route>
      <Route path='/room/:id' element={<ChatRoom />}></Route>
    </Routes>
  );
};

export default ChatRoutes;
