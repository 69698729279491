import { useEffect } from 'react';

import { isProduction } from 'src/lib/1/constant';

const devTitle = '[개발] ';

export const useTitle = (title: string) => {
  useEffect(() => {
    const prevTitle = document.title;
    if (!title || prevTitle === title) return;
    // 개발 문구가 여러번 추가되는 것을 방지한다.
    document.title = isProduction || title.includes(devTitle) ? title : devTitle + title;
    return () => {
      document.title = isProduction || prevTitle.includes(devTitle) ? prevTitle : devTitle + prevTitle;
    };
  }, [title]);
};
