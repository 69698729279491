import { FC } from 'react';

import { PurchaseOrderDoc } from 'src/lib/3/schema-purchase-order';

import PrintWindow from 'src/components/PrintWindow/PrintWindow';

import classes from './PurchaseOrderPrint.module.scss';

interface PurchaseOrderPrintProps {
  rowData: PurchaseOrderDoc[];
  selectedMethods: string[];
}

const PurchaseOrderPrint: FC<PurchaseOrderPrintProps> = ({ rowData, selectedMethods }) => {
  const data = rowData
    .filter((r) => selectedMethods.includes(r.purchaseMethod))
    .sort((a, b) => a.supplierName.localeCompare(b.supplierName));
  return (
    <PrintWindow>
      <div className={classes.purchaseOrderPrintList}>
        {/* {rowData.map((r) => r.supplierName)} */}
        <div className={classes.header}>
          <p>매입처</p>
          <p>내용</p>
        </div>
        {data.map((row) => (
          <div key={row._id} className={classes.purchaseOrderItem}>
            <p>{row.supplierName}</p>
            <p className={classes.orderMessage}>{row.message}</p>
          </div>
        ))}
      </div>
    </PrintWindow>
  );
};

export default PurchaseOrderPrint;
