import { Collapse } from 'antd';
import { FC, PropsWithChildren } from 'react';

interface CollapsibleListProps extends PropsWithChildren {
  id: string;
  label: string;
  defaultOpen?: boolean;
}

const CollapsibleList: FC<CollapsibleListProps> = ({ id, label, defaultOpen, children }) => {
  return (
    <Collapse
      items={[
        {
          key: id,
          label,
          children,
          style: {
            backgroundColor: 'white',
            borderRadius: 10,
          },
        },
      ]}
      defaultActiveKey={defaultOpen ? [id] : []}
      ghost={true}
      bordered={false}
      // size='large'
      // expandIconPosition='end'
    />
  );
};

export default CollapsibleList;
