import { LoadingOutlined } from '@ant-design/icons';
import { AccessMethod, DeliveryTaskDoc, StoreDoc } from '@gooduncles/gu-app-schema';
import { Button } from 'antd';
import { groupBy, uniqBy } from 'lodash-es';
import { FC } from 'react';
import useSWR from 'swr';

import { requiredKeyList } from 'src/lib/1/constant';
import { getStore } from 'src/lib/4/firebase-short-cut';

import AccessMethodGroup from 'src/atomic-components/atoms/AccessMethodGroup/AccessMethodGroup';
import StoreDeliveryMemoItem from 'src/atomic-components/atoms/StoreDeliveryNoteItem/StoreDeliveryNoteItem';

import classes from './DailyCheckListForPartner.module.scss';

const fetchStore = async (params: [string, string[]]) => {
  const results = await Promise.all(params[1].map(getStore));
  return results.filter(Boolean); // 유효한 데이터만 반환
};

/**
 * 필수지참 정보가 있는 가게만 필터링
 */
const getStoresWithNotesOrAccessInfo = (storeDocs: StoreDoc[]) => {
  const uniqStoreList = uniqBy(storeDocs, '_id');
  const deliveryMemoList = uniqStoreList
    .filter((store) => store.memo)
    .map((store) => ({
      storeName: store.storeNickname,
      deliveryMemo: store.memo,
    })) as { storeName: string; deliveryMemo: string }[];

  const accessInfoList = uniqStoreList
    .filter((store) => store.accessInfo)
    .flatMap(
      (store) => store.accessInfo?.map((info) => ({ storeName: store.storeNickname, method: info.method })) ?? []
    )
    .filter((info) => requiredKeyList.includes(info.method));

  const groupedAccessInfoList = groupBy(accessInfoList, 'method');

  return {
    deliveryMemoList,
    groupedAccessInfoList,
    accessInfoListLength: accessInfoList.length,
  };
};

interface DailyCheckListForPartnerProps {
  partnerName: string;
  deliveryTaskList: DeliveryTaskDoc[];
}

/**
 * 파트너별 일일 체크리스트를 표현합니다.
 */
const DailyCheckListForPartner: FC<DailyCheckListForPartnerProps> = ({ partnerName, deliveryTaskList }) => {
  // SWR을 배열 형태로 사용하여 여러 fetch 실행
  const { data, error, isLoading, mutate } = useSWR(
    ['getStores', deliveryTaskList.map((task) => task.locationId)],
    fetchStore,
    {
      shouldRetryOnError: false,
    }
  );
  const storeDocs = data?.filter((doc) => doc) as StoreDoc[];
  const { deliveryMemoList, accessInfoListLength, groupedAccessInfoList } = getStoresWithNotesOrAccessInfo(storeDocs);

  const refetchFailedQueries = () => {
    mutate(); // 모든 fetch 재시도
  };

  return (
    <div className={classes.checkListContainer}>
      <p className={classes.partnerName}>{partnerName}</p>
      <div className={classes.scrollList}>
        {/* Loading State */}
        {isLoading && <LoadingOutlined />}

        {/* Error State */}
        {error && (
          <div className={classes.errorBox}>
            <p>데이터를 가져오지 못했습니다. 네트워크 확인 후 다시 시도해주세요.</p>
            <Button onClick={refetchFailedQueries}>재시도</Button>
          </div>
        )}

        {/* Check List */}
        {!isLoading && !error ? (
          <>
            {/* Required Keys */}
            <section className={classes.section}>
              <p className={classes.sectionTitle}>필수 지참</p>
              {accessInfoListLength > 0 ? (
                Object.entries(groupedAccessInfoList).map(([method, stores]) => (
                  <AccessMethodGroup
                    key={method}
                    method={method as AccessMethod}
                    stores={stores.map((s) => s.storeName)}
                  />
                ))
              ) : (
                <p className={classes.empty}>확인할 내용이 없습니다.</p>
              )}
            </section>

            <div className={classes.divider} />

            {/* DeliveryMemo */}
            <section className={classes.section}>
              <p className={classes.sectionTitle}>배송 특이사항</p>
              {deliveryMemoList.length > 0 ? (
                deliveryMemoList.map((store, idx) => (
                  <StoreDeliveryMemoItem key={idx} storeNickname={store.storeName} deliveryNote={store.deliveryMemo} />
                ))
              ) : (
                <p className={classes.empty}>확인할 내용이 없습니다.</p>
              )}
            </section>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DailyCheckListForPartner;
