import { Button, Modal } from 'antd';
import { FC, useState } from 'react';

import OrderAndLogView from './OrderAndLogView';

interface OrderAndLogViewModalProps {
  orderId: string;
}

const OrderAndLogViewModal: FC<OrderAndLogViewModalProps> = ({ orderId }) => {
  const [open, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        type='text'
        onClick={openModal}
        style={{
          color: 'var(--blue400)',
        }}>
        주문 상세
      </Button>
      <Modal
        open={open}
        width={900}
        centered
        styles={{ body: { height: '80vh', overflow: 'scroll' } }}
        onCancel={closeModal}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText='닫기'
        closable={false}>
        <OrderAndLogView orderId={orderId} />
      </Modal>
    </>
  );
};

export default OrderAndLogViewModal;
