import { PartnersUserDoc } from '@gooduncles/gu-app-schema';
import { useCallback } from 'react';

import { deleteTimestamp } from 'src/lib/1/util';

import { setPartnersUsers } from '../slices/partners';
import { useAppDispatch } from '../store';

const usePartners = () => {
  const dispatch = useAppDispatch();

  const onSetPartnersUsers = useCallback(
    (users: PartnersUserDoc[]) => {
      dispatch(
        setPartnersUsers(
          users
            .map((user) => deleteTimestamp<PartnersUserDoc>(user))
            .sort((a, b) => a.deliveryDriverStatus.sortKey - b.deliveryDriverStatus.sortKey)
        )
      );
    },
    [dispatch]
  );

  return { onSetPartnersUsers };
};

export default usePartners;
