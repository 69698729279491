import useSWR from 'swr';

import { getStore } from 'src/lib/4/firebase-short-cut';

const useStoreById = (id: string | undefined) => {
  const {
    data,
    isValidating: storeLoading,
    error: storeError,
  } = useSWR(id ? id : null, getStore, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });

  return {
    store: data,
    storeLoading,
    storeError,
  };
};

export default useStoreById;
