import useSWR from 'swr';

import { getOrder } from 'src/lib/4/firebase-short-cut';

const useOrderById = (id: string | undefined) => {
  const {
    data,
    isValidating: orderLoading,
    error: orderError,
  } = useSWR(id ? id : null, getOrder, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });

  return {
    order: data,
    orderLoading,
    orderError,
  };
};

export default useOrderById;
