import { Timestamp } from 'firebase/firestore';
import useSWR, { BareFetcher } from 'swr';

import { dayjsesToDateWhere, getDefaultDates } from 'src/lib/1/date-util';
import { DailyProductStatsDoc } from 'src/lib/2/schema';
import { getDailyProductStats } from 'src/lib/4/firebase-short-cut';

const fetcher: BareFetcher<DailyProductStatsDoc[]> = async ([productId]: [string, string]) => {
  const defaultValue = getDefaultDates(30, 1);
  const [thirtyDaysAgo] = dayjsesToDateWhere(defaultValue[0], defaultValue[1]);
  return getDailyProductStats([
    ['productId', '==', productId],
    ['_timeCreate', '>=', Timestamp.fromDate(thirtyDaysAgo)],
  ]);
};

const useMonthlyProductStats = (productId: string) => {
  const { data: monthlyProductStats, isLoading: monthlyProductStatsLoading } = useSWR(
    productId ? [productId, 'monthlyProductStats'] : null,
    fetcher
  );
  return {
    monthlyProductStats: monthlyProductStats ?? [],
    monthlyProductStatsLoading,
  };
};

export default useMonthlyProductStats;
