import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select, Tabs } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { purchaseMethodToKr, sendNo } from 'src/lib/1/constant';
import { formatDate, getDefaultDate } from 'src/lib/1/date-util';
import { PurchaseOrderDoc } from 'src/lib/3/schema-purchase-order';
import { observePurchaseOrders } from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import classes from './PurchaseOrderDashboard.module.scss';

import PurchaseOrderPrint from './PurchaseOrderPrint/PurchaseOrderPrint';
import PurchaseOrderTable from './PurchaseOrderTable/PurchaseOrderTable';
import SendSmsRequestModal from './PurchaseOrderTable/SendSmsRequestModal/SendSmsRequestModal';

const defaultValue = getDefaultDate(1);

const PurchaseOrderDashboard: FC = () => {
  useTitle('GU 관리자 | 발주서');
  const navigate = useNavigate();
  const { tab } = useParams();
  const [rowData, setRowData] = useState<PurchaseOrderDoc[]>([]);
  const [showHeader, setShowHeader] = useState(false);
  const [purchaseMethods, setPurchaseMethods] = useState<string[]>(['kakaotalk', 'sms']);
  const [date, setDate] = useState<dayjs.Dayjs | null>(defaultValue);
  const disableAddADay = useMemo(() => (date?.unix() ? date?.unix() >= Date.now() / 1000 : false), [date]);
  const dateForWhere = useMemo(() => formatDate(date ? date?.toDate() : new Date(), 'yyyy-MM-dd'), [date]);

  const [selectedRows, setSelectedRows] = useState<PurchaseOrderDoc[]>([]);
  const requests = useMemo(() => {
    return selectedRows.map((row) => ({
      body: row.messagePrefix + row.message + row.messageSuffix + '\n내용문의는 010-6236-1736으로 연락주세요.',
      sendNo,
      recipientList: (row.supplierSubTels ? [row.supplierTel, ...row.supplierSubTels] : [row.supplierTel]).map(
        (tel) => ({
          recipientNo: tel.replace(/-/g, ''),
          recipientGroupingKey: row.supplierName,
        })
      ),
    }));
  }, [selectedRows]);

  const addADay = () => setDate((prev) => (prev ? prev.add(1, 'day') : null));
  const subADay = () => setDate((prev) => (prev ? prev.subtract(1, 'day') : null));
  const onChangeShowHeader = (e: CheckboxChangeEvent) => setShowHeader(e.target.checked);
  const onPurchaseMethodsChange = (value: string[]) => setPurchaseMethods(value);

  const onTabChange = (key: string) => {
    navigate(`../dashboard/${key}`);
  };

  useEffect(() => {
    if (!dateForWhere) {
      return;
    }
    const subscription = observePurchaseOrders([['date', '==', dateForWhere]]).subscribe(setRowData);
    return () => {
      subscription.unsubscribe();
    };
  }, [dateForWhere]);

  return (
    <div className={classes.dashboardContainer}>
      <div className={classes.buttonToolbar}>
        <div className={classes.buttons}>
          <Button icon={<LeftOutlined />} onClick={subADay} />
          <DatePicker onChange={setDate} defaultValue={defaultValue} value={date} />
          <Button icon={<RightOutlined />} onClick={addADay} disabled={disableAddADay} />
          {tab === 'table' && (
            <Checkbox checked={showHeader} onChange={onChangeShowHeader}>
              머릿말 표시
            </Checkbox>
          )}
        </div>
        {tab === 'table' && <SendSmsRequestModal requests={requests} />}
        {tab === 'print' && (
          <Select
            mode='multiple'
            allowClear
            style={{ minWidth: 200 }}
            placeholder='출력할 발주방식 선택'
            defaultValue={purchaseMethods}
            onChange={onPurchaseMethodsChange}
            options={Object.entries(purchaseMethodToKr).map(([key, value]) => ({ label: value, value: key }))}
          />
        )}
      </div>
      <Tabs
        type='card'
        accessKey={tab}
        style={{ height: '100%', overflow: 'scroll' }}
        tabBarStyle={{ marginBottom: 0 }}
        onChange={onTabChange}
        items={[
          {
            key: 'table',
            label: '기본 보기',
            children: (
              <PurchaseOrderTable rowData={rowData} setSelectedRows={setSelectedRows} showHeader={showHeader} />
            ),
          },
          {
            key: 'print',
            label: '출력용 보기',
            children: <PurchaseOrderPrint rowData={rowData} selectedMethods={purchaseMethods} />,
          },
        ]}
      />
    </div>
  );
};

export default PurchaseOrderDashboard;
