import { FC } from 'react';

import classes from './StoreDeliveryNoteItem.module.scss';

interface StoreDeliveryNoteItemProps {
  storeNickname: string;
  deliveryNote: string;
}

/**
 * 매장의 출입 정보 및 배송 특이사항을 표현합니다.
 */
const StoreDeliveryNoteItem: FC<StoreDeliveryNoteItemProps> = ({ storeNickname, deliveryNote }) => {
  return (
    <div className={classes.container}>
      <h3 className={classes.storeName}>{storeNickname}</h3>
      <p className={classes.note}>{deliveryNote}</p>
    </div>
  );
};

export default StoreDeliveryNoteItem;
