import { AES, enc } from 'crypto-js';
import { addDays, format } from 'date-fns';
import { isNumber } from 'lodash-es';
import { v4 } from 'uuid';

export const sessionId = v4();

export const formatNumber = (price: number | string, fraction = 6) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: fraction,
  }).format(isNumber(price) ? price : Number(price));
};

export const sleep = async (ms?: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getDeliveryDate = (days: number, orderDate?: Date) => {
  const now = orderDate ?? new Date();
  return format(new Date(now.setDate(now.getDate() + days)), "yyyy-MM-dd'T'08:00:00+0900");
};

export const getBase64 = (file: Blob) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const kmToMeter = (value: string) => (value ? Math.floor(parseFloat(value.replace(/\s|km/g, '')) * 1000) : null);

export const currencyToNumber = (value: string) => (value ? Number(value.replace(',', '')) : null);

export const sortByString = (a: string, b: string) => {
  return a < b ? -1 : a > b ? 1 : 0;
};

export const sortByDate = (a: Date, b: Date) => {
  return a.getTime() - b.getTime();
};

export async function getPublicIp() {
  try {
    const response = await fetch('https://api64.ipify.org?format=json', {
      cache: 'no-cache',
    });
    if (response.ok) {
      const body = await response.json();
      return body.ip as string;
    } else {
      return 'unknown';
    }
  } catch (error) {
    console.error('[getPublicIp]', error);
    return 'error';
  }
}

/**
 * {@link https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled Promise.allSettled}와 유사한 기능이다.
 * 다만 에러를 좀 더 수월하게 처리하기 위해 만들었다.
 */
export async function handleErrorsForPromiseAll<T>(promises: Promise<T>[], errorName = '[Errors]') {
  const results = await Promise.allSettled(promises);
  const errors = results.filter((result) => result.status === 'rejected');
  if (errors.length) {
    throw new TypeError(errorName, {
      // 불필요한 TypeError 문구는 제거한다.
      cause: errors.map((error) => String((error as PromiseRejectedResult).reason).replace(/TypeError: /, '')),
    });
  }
  return results.map((result) => (result as PromiseFulfilledResult<T>).value);
}

/**
 * 성공 실패를 나눠서 받는다.
 */
export async function promiseAllSettled<T>(promises: Promise<T>[]) {
  const results = await Promise.allSettled(promises);
  const fulfilled = results.filter((result) => result.status === 'fulfilled');
  const rejected = results.filter((result) => result.status === 'rejected');
  return {
    fulfilled: fulfilled.map((result) => (result as PromiseFulfilledResult<T>).value),
    // 불필요한 TypeError 문구는 제거한다.
    rejected: rejected.map((error) => String((error as PromiseRejectedResult).reason).replace(/TypeError: /, '')),
  };
}

/**
 * 값이 undefined인 경우에 대한 처리를 추가한 JSON.parse
 */
export function jsonParseWithCatch<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? 'null');
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export function setLocalStorageValue<T>(key: string, newValue: T) {
  localStorage.setItem(key, JSON.stringify(newValue));
}

export function getLocalStorageValue<T>(key: string) {
  const storedLocalValue = jsonParseWithCatch<T>(localStorage.getItem(key));
  return storedLocalValue;
}

export function removeLocalStorageValue(key: string) {
  localStorage.removeItem(key);
}

export const textSort = (a: string, b: string) => {
  return a < b ? -1 : a > b ? 1 : 0;
};

export const parseIfObject = (value: any) => {
  if (typeof value === 'object') {
    return value instanceof Error ? JSON.stringify(value, Object.getOwnPropertyNames(value)) : JSON.stringify(value);
  }
  return value;
};

export const errorObjectToString = (error: any) => (error instanceof Error ? error.message : parseIfObject(error));

export const downloadJSON = (obj: object, filename: string) => {
  // const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(obj));
  const downloadAnchorNode = document.createElement('a');
  const downloadUrl = URL.createObjectURL(new Blob([JSON.stringify(obj)], { type: 'text/json' }));
  downloadAnchorNode.setAttribute('href', downloadUrl);
  downloadAnchorNode.setAttribute('download', `${filename}.json`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

const matchOperatorsRe = /[|\\{}()[\]^$+*?.]/g;
export const escapeStringRegExp = (str: string) => {
  return new RegExp(str.replace(matchOperatorsRe, '\\$&'));
};

export const toNumberOrZero = (value: string) => {
  const number = Number(value.replace(/,/g, '').trim());
  return Number.isNaN(number) ? 0 : number;
};

export const downloadFromUrl = (url: string, filename: string) => {
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', url);
  downloadAnchorNode.setAttribute('download', filename);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

/**
 * value가 null, undefined, ''인 경우에는 null을 리턴한다.
 */
export const excelRowValidation = (value: string) => {
  return value ? (value.length > 0 ? value : null) : null;
};

/** 특정 문자를 배열의 앞으로 위치시킨다. */
export const moveItemsToFrontInArray = (arr: string[], items: string[]) => {
  const front = items.filter((i) => arr.includes(i));
  const rear = arr.filter((i) => !items.includes(i));
  return [...front, ...rear];
};

export const encryptAES = (text: string) => {
  const key = process.env.REACT_APP_PROJECT_ID;
  if (!key) {
    throw new Error('No key');
  }
  return AES.encrypt(text, key).toString();
};

export const decryptAES = (text: string) => {
  const key = process.env.REACT_APP_PROJECT_ID;
  if (!key) {
    throw new Error('No key');
  }
  return AES.decrypt(text, key).toString(enc.Utf8);
};

export const encodeBase64 = (text: string) => {
  return enc.Base64.stringify(enc.Utf8.parse(text));
};

export const decodeBase64 = (text: string) => {
  return enc.Base64.parse(text).toString(enc.Utf8);
};

/**
 * datadog에 바로갈 수 있는 링크를 생성한다.
 */
export const getDataDogLinkForUser = (
  email: string,
  options?: {
    // 시작일 (timestamp)
    from?: string;
    // 끝
    to?: string;
  }
) => {
  const url = 'https://us3.datadoghq.com/rum/explorer';
  const baseQuery = `@type:session @session.has_replay:true env:production @usr.email:${email}`;

  const { from, to } = options ?? {};
  const from_ts = from ?? addDays(Date.now(), -7).getTime();
  const to_ts = to ?? Date.now();
  const detailQeury = `&saved-view-id=2919&sort_by=time&sort_order=desc&viz=stream&from_ts=${from_ts}&to_ts=${to_ts}&live=true`;

  return window.open(url + '?query=' + encodeURIComponent(baseQuery) + detailQeury, '_blank');
};

/**
 * - 를 삽입한다.
 */
export function normalizeTel(telNo?: string) {
  if (telNo == null || telNo === '') {
    return '';
  }
  // 숫자 이외에는 모두 제외한다.
  telNo = telNo.replace(/[^0-9]/g, '');

  // 2018-11-15 부터는 050으로 변환해서 FS에 저장하기 때문에 불펼요할 수 있다.
  telNo = telNo.replace(/^090/, '050');

  // 010- , 070-
  let matches = telNo.match(/^(0[17][01])(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050은 4자리 식별번호를 사용하지만 3자리가 익숙하니 12자리가 아닌 경우에는 050에서 끊어준다.
  // 050-AAA?-BBBB
  matches = telNo.match(/^(050)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050X-AAAA-BBBB
  matches = telNo.match(/^(050.)(.{4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(02)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(0..)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  return telNo;
}

/**
 * 마우스 클릭시 부모가 사라지면서 붙여놨던 onClick이벤트가 발생하지 않는 문제를 해결하기 위해
 * 기본 이벤트를 막아야한다.
 */
export const preventMouseDown = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
};

export const phoneNumberRegex = /^010-?\d{3,4}-?\d{4}$/;

/**
 * 숫자의 소수점 자리수를 구한다.
 */
export const getDecimalLength = (num: number) => {
  const str = num.toString();
  const dotIndex = str.indexOf('.');

  if (dotIndex === -1) return 0;

  return str.length - dotIndex - 1;
};

export const getStepFromNumber = (num: number) => {
  const decimalLength = getDecimalLength(num);

  return 1 / Math.pow(10, decimalLength);
};

/**
 * NHN에서 허용하는 바이트 기준을 맞추기 위한 계산 코드
 * 문자 크기 계산은 UTF-8이 아닌 EUC-KR을 기준으로 한다.
 */
export const getByteSizeForEUCKR = (str: string) =>
  [...str].reduce((byteSize, char) => {
    const codePoint = char.charCodeAt(0);

    if (codePoint <= 0x007f) {
      return byteSize + 1;
    }
    return byteSize + 2;
  }, 0);

/**
 * 사용자의 입력값이 유요한 숫자인지 확인한다.
 * @param input
 * @returns
 */
export const isValidNumber = (input: string) => {
  // 숫자 패턴을 나타내는 정규표현식
  const numberPattern = /^\d+$/;

  // 정규표현식을 사용하여 패턴 일치 여부 확인
  return numberPattern.test(input);
};

/**
 * 문자열을 N자리로 나누어 줄바꿈을 추가한다.
 */
export const addLineBreaks = (str: string, length = 16) =>
  str.length <= length ? str : str.match(new RegExp(`.{1,${length}}`, 'g'))?.join('\n');

/**
 * 원단위를 10원 단위로 반올림한다.
 */
export const roundToNearestTen = (num: number) => Math.round(num / 10) * 10;

/**
 * 숫자를 최소값과 최대값 사이의 비율로 변환한다.
 * @param value
 * @param min
 * @param max
 * @returns
 */
export const scaleDiff = (value: number, min: number, max: number) => {
  if (min === max) {
    return 0;
  }
  return ((value - min) / (max - min)) * 100;
};

export const storeCodeRegex = /R(\d{5})/;

/**
 * redux & ga에서 사용불가한
 * Timestamp를 제거한다.
 */
export const deleteTimestamp = <T>(data: any) => {
  delete data._timeUpdate;
  delete data._timeCreate;
  delete data._timeDelete;
  return data as T;
};
