import { FC } from 'react';

import classes from './ListRow.module.scss';

interface ListRowProps {
  title?: string | JSX.Element;
  label?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  detail?: string | JSX.Element;
  onClick?: () => void;
}

const ListRow: FC<ListRowProps> = ({ title, label, subtitle, detail, onClick }) => {
  return (
    <div className={classes.listRow} onClick={onClick}>
      <div className={classes.titleAndSubtitle}>
        {label && <p className={classes.subtitle}>{label}</p>}
        {title && <p className={classes.title}>{title}</p>}
        {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
      </div>
      <div className={classes.accessories}>{detail && <div className={classes.detail}>{detail}</div>}</div>
    </div>
  );
};

export default ListRow;
