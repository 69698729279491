import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import useCommerceConf from 'src/hooks/useCommerceConf';

import ProductBinLocationDashboard from 'src/components/ProductBinLocationDashboard/ProductBinLocationDashboard';
import ProductRequestDashboard from 'src/components/ProductRequestDashboard/ProductRequestDashboard';
import PurchaseOrderDashboard from 'src/components/PurchaseOrder/PurchaseOrderDashboard';
import PurchaseOrderPreview from 'src/components/PurchaseOrder/PurchaseOrderPreviewDashboard';
import Supplier from 'src/components/PurchaseOrder/Supplier/Supplier';

const PurchaseOrderPage: FC = () => {
  const { commerceConf } = useCommerceConf();
  return (
    <Routes>
      <Route path='/dashboard/:tab' element={<PurchaseOrderDashboard />} />
      <Route path='/supplier' element={<Supplier />} />
      <Route path='/supplier/:supplier' element={<Supplier />} />
      <Route path='/preview' element={<PurchaseOrderPreview deliveryDateRules={commerceConf?.deliveryDateRules} />} />
      <Route path='/product-request' element={<ProductRequestDashboard />} />
      <Route path='/product-bin-location' element={<ProductBinLocationDashboard />} />
      <Route path='/' element={<Navigate to='./preview' replace />} />
    </Routes>
  );
};

export default PurchaseOrderPage;
