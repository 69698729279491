import { ProductsForSupplierEntry } from '@gooduncles/gu-app-schema';
import { FC, Fragment } from 'react';

import classes from './ProductsForSupplierList.module.scss';

const ProductsForSupplierList: FC<{ data: ProductsForSupplierEntry }> = ({ data }) => {
  const sum = Object.values(data).reduce((acc, cur) => {
    const sum = Object.values(cur).reduce((acc, cur) => {
      const sum = Object.values(cur).reduce((acc, cur) => {
        return acc + cur;
      }, 0);
      return acc + sum;
    }, 0);
    return acc + sum;
  }, 0);

  return (
    <div className={classes.productsForSupplierList}>
      <div className={classes.header}>
        <p>매입처</p>
        <p>품명</p>
        <p>담당자</p>
        <p>수량(합계)</p>
      </div>
      {Object.entries(data)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([supplierName, products]) => (
          <div key={supplierName} className={classes.supplierItem}>
            <p>{supplierName}</p>
            <div className={classes.productItem}>
              {Object.entries(products).map(([productName, partners]) => {
                return (
                  <Fragment key={productName}>
                    <p className={classes.productName}>{productName}</p>
                    <div className={classes.partnerList}>
                      {Object.entries(partners).map(([partner, volume]) => {
                        return (
                          <div key={productName + partner} className={classes.partnerItem}>
                            <p>{partner}</p>
                            <p className={classes.volume}>{volume}</p>
                          </div>
                        );
                      })}
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        ))}
      <div className={classes.footer}>
        <p>총합계</p>
        <p />
        <p />
        <p className={classes.volume}>{sum}</p>
      </div>
    </div>
  );
};

export default ProductsForSupplierList;
