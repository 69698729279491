import { AccessInfo, AccessMethods } from '@gooduncles/gu-app-schema';
import { FC, useCallback, useState } from 'react';

import { updateStore } from 'src/lib/4/firebase-short-cut';

import KeyValueTagForm from 'src/atomic-components/molecules/KeyValueTagForm/KeyValueTagForm';

interface AccessInfoCellEditor {
  storeId: string;
  value: AccessInfo[] | null;
  stopEditing: () => void;
}

const AccessInfoCellEditor: FC<AccessInfoCellEditor> = ({ storeId, value, stopEditing }) => {
  const [loading, setLoading] = useState(false);
  const setAccessInfo = useCallback(
    async (accessInfo0: AccessInfo[]) => {
      setLoading(true);
      const accessInfo = accessInfo0.length > 0 ? accessInfo0 : null;
      try {
        if (!storeId) {
          throw new Error('storeId is required');
        }
        await updateStore(storeId, { accessInfo });
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    },
    [storeId]
  );

  return (
    <KeyValueTagForm
      initialKeyValueList={value || []}
      keyLabel='출입 방법'
      keyPlaceholder='출입 방법 선택'
      valueLabel='설명'
      valuePlaceholder='터치 -> 12345*'
      options={Object.entries(AccessMethods).map(([key, value]) => ({ key, label: value }))}
      loading={loading}
      onTagsChange={setAccessInfo}
      onClose={stopEditing}
    />
  );
};

export default AccessInfoCellEditor;
