import { Dropdown, Layout, MenuProps, message } from 'antd';
import { FC, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { version } from 'src/lib/1/constant';
import { FirebaseManager } from 'src/lib/3/firebase-manager';

import classes from './AppLayout.module.scss';

import { useAuth } from '../../stores/auth-context';
import MainNavigation from './MainNavigation';

const { Content, Footer } = Layout;

const firebaseManager = FirebaseManager.getInstance();

const sendPasswordResetEmail = async (email?: string) => {
  if (!email) {
    message.error('이메일 정보가 없습니다.');
    return;
  }
  await firebaseManager.sendPasswordResetEmail(email);
  message.info('비밀번호 변경 이메일이 발송되었습니다.');
};

const AppLayout: FC = () => {
  const { authUser, signout, user } = useAuth();

  const items: MenuProps['items'] = useMemo(() => {
    return authUser
      ? [
          {
            key: '1',
            label: <button onClick={() => sendPasswordResetEmail(authUser.email ?? undefined)}>비밀번호 변경</button>,
          },
          {
            key: '2',
            label: <button onClick={signout}>로그아웃</button>,
          },
        ]
      : [];
  }, [authUser, signout]);

  return (
    <Layout style={{ height: '100vh' }} className={`${classes.appLayout} no-print`}>
      {authUser && <MainNavigation />}
      <Layout>
        <header className={classes.pageHeader}>
          <p className={classes.pageTitle}>{authUser ? '' : '로그인이 필요합니다.'}</p>
          {user && (
            <div className={classes.extra}>
              <span key={'session'} className={classes.session}>
                {version}
              </span>
              <Dropdown key={'loginMenu'} menu={{ items }} placement='bottomLeft'>
                <a style={{ color: '#2c2c2c' }} onClick={(e) => e.preventDefault()}>
                  | {user.email}
                </a>
              </Dropdown>
            </div>
          )}
        </header>
        <Content className={classes.content}>
          <div className={classes.contentContainer}>
            <Outlet />
          </div>
        </Content>
        <Footer className={classes.footer}>ⓒ Gooduncles</Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
