import { PushMessageDoc } from '@gooduncles/gu-app-schema';
import { useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';

import { formatDate } from 'src/lib/1/date-util';
import { FirebaseManager, QueryOptions, WHERE } from 'src/lib/3/firebase-manager';

import { ChatMessage } from '../schema/chat';
import { transformPushToChatMessage } from '../utils/chat-util';

const firebaseManager = FirebaseManager.getInstance();

const pushMessageForPartnersPath = 'pushMessageForPartners';
const observePushMessageForPartners = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<PushMessageDoc>(pushMessageForPartnersPath, where, options);

/**
 * 관리자와 해당 사용자의 대화 내용을 가져오는 훅
 */
const usePushForPartnerByAdmin = (adminId?: string, partnerId?: string) => {
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);

  useEffect(() => {
    if (!partnerId || !adminId) {
      return;
    }
    // 24시간 전 메시지 필터필터
    const aDayAgo = formatDate(new Date(Date.now() - 24 * 60 * 60 * 1000), "yyyy-MM-dd'T'HH:mm:ss+0900");
    // 관리자가 해당 사용자에게 보낸 모든 메시지
    const sentPushMessageObservable = observePushMessageForPartners([
      ['receiverId', '==', partnerId],
      ['senderType', '==', 'admin'],
      ['createdAt', '>', aDayAgo],
    ]);

    // 해당 사용자가 관리자에게 발송한 메시지
    const receivedPushMessageObservable = observePushMessageForPartners([
      ['senderId', '==', partnerId],
      ['receiverType', '==', 'admin'],
      ['createdAt', '>', aDayAgo],
    ]);

    const subscription = combineLatest([sentPushMessageObservable, receivedPushMessageObservable]).subscribe(
      ([sentRequests, receivedRequests]) => {
        setChatMessages(transformPushToChatMessage(sentRequests, receivedRequests));
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [adminId, partnerId]);

  return chatMessages;
};

export default usePushForPartnerByAdmin;
