import { PartnersUserDoc, PickupTaskDoc, TaskOrderCollection } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef, RowNode } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useEffect, useMemo, useState } from 'react';

import { formatDate } from 'src/lib/1/date-util';
import { observePickupTask } from 'src/lib/4/firebase-short-cut';

import AgCellPartnerName from 'src/atomic-components/atoms/AgCellPartnerName/AgCellPartnerName';
import EditPickupTaskModal from 'src/atomic-components/molecules/EditTaskModal/EditPickupTaskModal';
import Loading from 'src/components/Loading/Loading';

export type PickupTaskRowData = PickupTaskDoc & {
  partnersUser: PartnersUserDoc | null;
};

interface PickupTaskTableProps {
  date: string;
  partnersUsers: PartnersUserDoc[];
  setSelectedRows: React.Dispatch<React.SetStateAction<PickupTaskRowData[]>>;
}

const defaultColDef: ColDef<PickupTaskRowData> = {
  sortable: true,
  resizable: true,
  filter: true,
};

const orderCollectionMap = {
  [TaskOrderCollection.Order]: '주문',
  [TaskOrderCollection.StoreIssue]: '이슈',
  [TaskOrderCollection.PurchaseOrder]: '사입',
};

const dateFormatter = ({ value }: { value: string | null }) => (value ? formatDate(value, 'HH:mm:ss') : '');
const isRowSelectable = (node: RowNode<PickupTaskRowData>) => {
  const data = node.data;
  if (!data) return false;
  // 이미 완료된 작업은 선택할 수 없다.
  if (data.finishedAt) return false;
  return true;
};

const PickupTaskTable: FC<PickupTaskTableProps> = ({ date, partnersUsers, setSelectedRows }) => {
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<PickupTaskRowData[]>([]);

  const columnDefs: (ColDef<PickupTaskRowData> | ColGroupDef<PickupTaskRowData>)[] = useMemo(
    () => [
      {
        width: 50,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {
        field: 'partnersUser.nickname',
        headerName: '파트너',
        width: 120,
        cellRenderer: AgCellPartnerName,
      },
      {
        field: '_id',
        hide: true,
      },
      {
        field: 'orderId',
        hide: true,
      },
      {
        field: 'locationName',
        headerName: '픽업장소',
      },
      {
        field: 'deliveryLocation.locationName',
        headerName: '배송장소',
      },
      {
        field: 'finishedAt',
        headerName: '완료 시각',
        width: 120,
        valueFormatter: dateFormatter,
      },
      {
        headerName: '픽업 이슈',
        width: 120,
        valueFormatter: (params: any) => {
          const data = params.data as PickupTaskRowData;
          const issues = Object.values(data.pickupItems)
            .filter((item) => item.issue !== null)
            .map((item) => item.issue)
            .join(', ');
          return issues;
        },
      },
      {
        field: 'cancelReason',
        headerName: '취소여부',
        width: 120,
        valueFormatter: (params: any) => params.value ?? '',
      },
      {
        field: 'orderCollection',
        headerName: '주문/이슈',
        width: 120,
        valueGetter: (params: any) => {
          const data = params.data as PickupTaskRowData;
          return data ? orderCollectionMap[data.orderColleciton] : '';
        },
      },
      {
        field: '_id',
        headerName: '상세',
        width: 100,
        cellRenderer: (params: any) => {
          const value: string = params.value;
          return <EditPickupTaskModal pickupTaskId={value} />;
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (!date || partnersUsers.length === 0) return;
    setLoading(true);
    const subscription = observePickupTask([['date', '==', date]]).subscribe((docs) => {
      const rowData: PickupTaskRowData[] = docs
        .map((doc) => {
          const partnersUser = partnersUsers.find((partner) => partner._id === doc.partnerId) ?? null;
          return { ...doc, partnersUser };
        })
        .sort((a, b) => b.locationGroupSortKey - a.locationGroupSortKey);
      setRowData(rowData);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [date, partnersUsers]);

  return (
    <div className='ag-theme-alpine height100'>
      {loading && <Loading title='데이터를 불러오고 있습니다.' />}
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data._id}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        // 행 선택을 허용한다.
        rowSelection='multiple'
        isRowSelectable={isRowSelectable}
        onSelectionChanged={(e) => setSelectedRows(e.api.getSelectedRows())}
        // 행 선택을 켜놓더라도 개별 셀의 내용을 복사할 수 있도록한다.
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default PickupTaskTable;
