import { OrderDoc, ProductStateCode } from '@gooduncles/gu-app-schema';
import { FC } from 'react';

import { orderStatusKr, userRoleKr } from 'src/lib/1/constant';
import { formatDate } from 'src/lib/1/date-util';
import { formatNumber } from 'src/lib/1/util';

import useStoreById from 'src/hooks/useStoreById';
import useUserById from 'src/hooks/useUserById';

import CollapsibleList from 'src/atomic-components/atoms/CollapsibleList/CollapsibleList';
import ListRow from 'src/atomic-components/atoms/ListRow/ListRow';

import classes from './OrderView.module.scss';

interface OrderViewProps {
  order: OrderDoc;
}

const OrderView: FC<OrderViewProps> = ({ order }) => {
  const { _id, userId, storeId, products, orderDate } = order;
  const { user } = useUserById(userId);
  const { store } = useStoreById(storeId);

  return (
    <div className={classes.orderViewContent}>
      <CollapsibleList id={_id + orderDate} label='주문 정보' defaultOpen={true}>
        <ListRow title='orderId' detail={_id} />
        <ListRow title='주문 생성 시각' detail={formatDate(orderDate, 'yyyy-MM-dd HH:mm:ss')} />
        <ListRow title='배송일' detail={order.date ?? ''} />
        <ListRow title='주문 상태' detail={orderStatusKr[order.orderStatus]} />
        <ListRow title='총 합계(배송비 포함)' detail={formatNumber(order.grandTotal)} />
      </CollapsibleList>

      {store && (
        <CollapsibleList id={storeId} label='매장 정보' defaultOpen={true}>
          <ListRow title='매장명' detail={store.storeNickname} />
          {/* <ListRow title='배송지' detail={store.deliverySpotId} /> */}
        </CollapsibleList>
      )}

      {user && (
        <CollapsibleList id={userId} label='주문자 정보' defaultOpen={true}>
          <ListRow title='이메일' detail={user.email} />
          <ListRow title='전화번호' detail={user.userTel} />
          <ListRow title='계정 등급' detail={userRoleKr[user.role]} />
        </CollapsibleList>
      )}

      <CollapsibleList id={_id} label='주문 상품' defaultOpen={true}>
        <ListRow key='deliveryFee' title='배송비' detail={formatNumber(order.deliveryFee ?? 0)} />
        {products.map((product) => {
          const isOutOfStock = product.state === ProductStateCode.OUTOFSTOCK;
          return (
            <ListRow
              key={product.productId}
              title={
                <span
                  style={{
                    textDecoration: isOutOfStock ? 'line-through' : 'none',
                  }}>
                  {product.fullName}
                </span>
              }
              subtitle={
                <>
                  <span>{`${formatNumber(product.price)} x ${product.volume ?? 1}`}</span>
                  {isOutOfStock && <span className={classes.outOfStock}>품절</span>}
                </>
              }
              detail={formatNumber(product.price * (product.volume ?? 1))}
            />
          );
        })}
      </CollapsibleList>
    </div>
  );
};

export default OrderView;
