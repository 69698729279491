import useSWR from 'swr';

import { getUser } from 'src/lib/4/firebase-short-cut';

const useUserById = (id: string | undefined) => {
  const {
    data,
    isValidating: userLoading,
    error: userError,
  } = useSWR(id ? id : null, getUser, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });

  return {
    user: data,
    userLoading,
    userError,
  };
};

export default useUserById;
