import { PackingInfoProductEntry } from '@gooduncles/gu-app-schema';
import { FC } from 'react';

import classes from './EggProductList.module.scss';

const EggProductList: FC<{ data: PackingInfoProductEntry }> = ({ data }) => {
  const sum = Object.values(data).reduce((acc, cur) => {
    const sum = Object.values(cur).reduce((acc, cur) => {
      const sum = Object.values(cur).reduce((acc, cur) => {
        return acc + cur;
      }, 0);
      return acc + sum;
    }, 0);
    return acc + sum;
  }, 0);

  return (
    <div className={classes.eggProductList}>
      <div className={classes.header}>
        <p>품명</p>
        <p>담당자</p>
        <p>수량</p>
        <p>수량(전체 항목 세기)</p>
      </div>
      {Object.entries(data).map(([productName, partners]) => (
        <div key={productName} className={classes.productItem}>
          <p>{productName}</p>
          <div className={classes.partnerList}>
            {Object.entries(partners).map(([partner, bundles]) => {
              return (
                <div key={productName + partner} className={classes.partnerItem}>
                  <p>{partner}</p>
                  <div className={classes.bundleGroupItem}>
                    {Object.entries(bundles).map(([bundle, volume]) => {
                      return (
                        <div key={productName + partner + bundle} className={classes.bundleItem}>
                          <span>{bundle}</span>
                          <span>{volume}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
      <div className={classes.footer}>
        <p>총합계</p>
        <p />
        <p />
        <p className={classes.volume}>{sum}</p>
      </div>
    </div>
  );
};

export default EggProductList;
