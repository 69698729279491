import { AccessInfo, OrderDoc, StoreDoc, paymentMethods, paymentVendors } from '@gooduncles/gu-app-schema';
import { DeliverySpotDoc } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, RowClassParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button, Checkbox, message, notification } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { get, groupBy } from 'lodash-es';
import { FC, useCallback, useMemo, useState } from 'react';
import { useAuth } from 'src/stores/auth-context';
import { cellStyleForPaymentMethod } from 'src/utils/payment-util';

import { StoreCategory, storeCategories, storeStateList } from 'src/lib/1/constant';
import { datesToStringDateWhere, getThisMonthFirstDate } from 'src/lib/1/date-util';
import { errorObjectToString, formatNumber } from 'src/lib/1/util';
import { MonthlyOrderStatsForStoreDoc } from 'src/lib/2/schema';
import { updateStore } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';
import {
  loadGridState,
  onCellValueChangedWithUpdate,
  onValueSetterWithValidation,
  resetGridState,
  saveGridState,
  timestampFormatter,
} from 'src/lib/6/ag-grid-util';

import useDeliveredOrdersWithRange from 'src/hooks/useDeliveredOrdersWithRange';

import AcccessInfoTag from 'src/atomic-components/atoms/AccessInfoTag/AccessInfoTag';
import AccessInfoCellEditor from 'src/atomic-components/organisms/AccessInfoCellEditor/AccessInfoCellEditor';
import { StateCellRenderer } from 'src/components/AgGrid/CellRenderer/StoreStateCellRenderer/StoreStateCellRenderer';
import DeliverySpotSelector from 'src/components/Common/DeliverySpotSelector/DeliverySpotSelector';
import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';
import HelpModal from 'src/components/Modal/HelpModal/HelpModal';

import classes from './StoreTable.module.scss';

import ChargeDeliveryFeeCellRenderer from './ChargeDeliveryFeeCellRenderer/ChargeDeliveryFeeCellRenderer';
import StoreCategoryCellRenderer from './StoreCategoryCellRenderer/StoreCategoryCellRenderer';

const logger = ConsoleLogger.getInstance();
const logName = '매장 관리';

type RowData = StoreDoc & {
  stats: MonthlyOrderStatsForStoreDoc | undefined;
  deliverySpot: DeliverySpotDoc | undefined;
};
type StoreTableProps = {
  rowData: RowData[];
  deliverySpots: DeliverySpotDoc[];
};

const defaultColDef: ColDef<RowData> = {
  sortable: true,
  resizable: true,
  filter: true,
};

const regexForOfficialStoreCode = /R1(\d{4})/;
const validateNotionUrlStart = (url: string) => {
  const regex = /^https:\/\/www\.notion\.so\//;
  return regex.test(url);
};

const onCellValueChanged = onCellValueChangedWithUpdate<RowData>('매장 관리');
const firstDate = getThisMonthFirstDate();
const [leftDate, rightDate] = datesToStringDateWhere(firstDate, new Date());
const groupByStore = (orders: OrderDoc[] | undefined) => {
  return groupBy(orders, (order) => order.storeId);
};
const getRowStyle = (params: RowClassParams<RowData>) => {
  if (params.data?.businessStatus === '폐업자') {
    return { backgroundColor: '#E1E1E1' };
  }
};

const StoreTable: FC<StoreTableProps> = ({ rowData: rowData0, deliverySpots }) => {
  const [gridRef, setGridRef] = useState<AgGridReact<RowData> | null>(null);
  const { user } = useAuth();
  const [avgForThisMonth, setAvgForThisMonth] = useState<boolean>(false);
  const { orders: ordersForThisMonth } = useDeliveredOrdersWithRange(leftDate, avgForThisMonth ? rightDate : null);
  const [storeCodeFilter, setStoreCodeFilter] = useState<boolean>(true);
  const [storeStateFilter, setStoreStateFilter] = useState<boolean>(true);
  const rowData = useMemo(() => {
    return rowData0.filter(
      (row) =>
        (!storeCodeFilter || row.storeCode?.match(regexForOfficialStoreCode)) &&
        (!storeStateFilter || row.state === 'open')
    );
  }, [rowData0, storeCodeFilter, storeStateFilter]);

  const onChangeAvgForThisMonth = useCallback((e: CheckboxChangeEvent) => {
    setAvgForThisMonth(e.target.checked);
  }, []);

  const onChangeStoreCodeFilter = useCallback((e: CheckboxChangeEvent) => {
    setStoreCodeFilter(e.target.checked);
  }, []);

  const onChangeStoreStateFilter = useCallback((e: CheckboxChangeEvent) => {
    setStoreStateFilter(e.target.checked);
  }, []);

  const columnDefs: ColDef<RowData>[] = useMemo(() => {
    return [
      { field: '_id', hide: true },
      {
        field: '_timeCreate',
        hide: true,
        valueFormatter: timestampFormatter,
      },
      { field: 'storeCode', headerName: '업소코드', maxWidth: 110 },
      { field: 'storeName', headerName: '매장명', hide: true },
      { field: 'storeNickname', headerName: '매장명(관리)' },
      {
        field: 'categories',
        headerName: '업종',
        valueGetter: ({ data }) => data?.categories?.map((v) => storeCategories[v as StoreCategory]),
        cellRenderer: StoreCategoryCellRenderer,
      },
      {
        field: 'ownerTel',
        headerName: '*관리자 전화번호',
        headerTooltip: '카톡 메시지 수신자',
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
        onCellValueChanged: (params) => onCellValueChanged(params, 'store', params.data._id, 'ownerTel'),
      },
      {
        field: 'taxInvoiceEmail',
        headerName: '*세금계산서 이메일',
        headerTooltip: 'taxInvoiceEmail',
        editable: true,
        cellEditor: 'agTextCellEditor',
        onCellValueChanged: (params) =>
          onCellValueChanged(params, 'store', params.data._id, 'taxInvoiceEmail', true, true),
      },
      {
        field: 'stats.totalAmount',
        headerName: '지난달 총 주문금액',
        filter: 'agNumberColumnFilter',
        maxWidth: 160,
        cellStyle: (params) => {
          const totalAmount = params.value as number;
          if (totalAmount > 200 * 10000) {
            return { color: 'var(--blue400)', textAlign: 'right', fontWeight: 500 } as any;
          }
          return { textAlign: 'right' };
        },
        valueFormatter: ({ value }) => formatNumber(value ?? 0),
      },
      {
        field: 'stats.orderAvg',
        headerName: '지난달 평균 단가',
        filter: 'agNumberColumnFilter',
        maxWidth: 140,
        cellStyle: (params) => {
          const { stats } = params.node.data ?? {};
          const orderAvg = stats?.orderAvg ?? 0;
          const orderSum = stats?.orderSum ?? 0;
          if (orderAvg > 0 && orderAvg < 50000 && orderSum < 200 * 10000) {
            return { backgroundColor: '#f8d7da', textAlign: 'right' } as any;
          }
          return { textAlign: 'right' };
        },
        valueFormatter: ({ value }) => formatNumber(value ?? 0, 0),
      },
      {
        headerName: '이번달 누적 금액',
        hide: !avgForThisMonth,
        valueGetter: ({ context, data }) => {
          if (!ordersForThisMonth || !data) {
            return null;
          }
          const ordersForStore = context.ordersForStore as Record<string, OrderDoc[]>;
          const storeId = data._id;
          const orders = ordersForStore[storeId];
          const orderSum = orders?.reduce((acc, order) => acc + order.paidAmount, 0) ?? 0;
          return orderSum;
        },
        valueFormatter: ({ value }) => formatNumber(value ?? 0),
        maxWidth: 140,
        cellStyle: { textAlign: 'right' },
      },
      {
        headerName: '이번달 평균 단가',
        hide: !avgForThisMonth,
        valueGetter: ({ context, data }) => {
          if (!data) {
            return null;
          }
          const ordersForStore = context.ordersForStore as Record<string, OrderDoc[]>;
          const storeId = data._id;
          const orders = ordersForStore[storeId];
          const orderSum = orders?.reduce((acc, order) => acc + order.paidAmount, 0) ?? 0;
          const orderAvg = orderSum / (orders?.length ?? 1);
          return orderAvg;
        },
        valueFormatter: ({ value }) => formatNumber(value ?? 0, 0),
        maxWidth: 140,
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'chargeDeliveryFee',
        headerName: '배송비 부과',
        cellRenderer: ChargeDeliveryFeeCellRenderer,
        maxWidth: 120,
      },
      {
        field: 'state',
        headerName: '매장상태',
        cellRenderer: StateCellRenderer,
        maxWidth: 120,
        editable: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          values: Object.keys(storeStateList),
          cellRenderer: StateCellRenderer,
        },
        valueSetter: (params) =>
          onValueSetterWithValidation(params, ['_id'], (value) => {
            if (value === 'open' && !params.data.storeCode) {
              notification.error({
                message: '매장코드가 없습니다.',
                description: '작업이 어려운 경우 슬랙으로 요청해주세요.',
              });
              const email = params.context.user.email;
              logger.logConsole(
                `${logName} - 코드가 없는 매장을 '운영'상태로 변경하려는 시도가 감지되었습니다.\n매장명: ${params.data.storeNickname}\n요청자: ${email}`,
                {
                  channel: '4-앱-알림',
                }
              );
              return false;
            }
            return true;
          }),
        onCellValueChanged: async (params) => {
          const newValue = get(params.data, 'state');
          const docId = params.data._id;
          if (!docId) {
            message.error('id값이 없습니다.');
            return;
          }
          try {
            await updateStore(params.data._id, { state: newValue });
            message.success('변경완료');
            if (!params.oldValue) {
              const email = params.context.user.email;
              logger.logConsole(
                `${logName} - 매장코드 최초 등록\n매장명: ${params.data?.storeName}, 매장코드: ${newValue}\n작업자: ${email}`,
                {
                  channel: '4-앱-알림',
                }
              );
            }
          } catch (error) {
            const msg = errorObjectToString(error);
            console.error(msg);
            message.error(msg ?? '알 수 없는 에러 발생!');
            logger.logConsole(`${logName} - store/${params.data._id}.state:: ${msg}`, {
              level: 'error',
            });
          }
        },
      },
      {
        headerName: '주소',
        minWidth: 260,
        field: 'address',
      },
      {
        field: 'deliverySpotId',
        minWidth: 260,
        headerName: '배송지점',
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: (params: any) => {
            const value: string = params.value;
            return value ? deliverySpots.find((spot) => spot._id === value)?.title : '미지정';
          },
        },
        cellRenderer: (params: any) => {
          const deliverySpotId = params.value ?? null;
          const store: StoreDoc = params.data;
          const deliverySpots: DeliverySpotDoc[] = params.context.deliverySpots;
          return <DeliverySpotSelector spots={deliverySpots} store={store} storeDeliverySpotId={deliverySpotId} />;
        },
      },
      {
        field: 'locationInfoUrl',
        headerName: '*배송지 노션',
        cellStyle: (params) => {
          const { locationInfoUrl, state } = params.node.data ?? {};
          if (state === 'open' && !locationInfoUrl) {
            return { backgroundColor: '#f8d7da' } as any;
          }
        },
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueSetter: (params) =>
          onValueSetterWithValidation(params, ['_id'], validateNotionUrlStart, {
            removable: true,
          }),
        onCellValueChanged: (params) => onCellValueChanged(params, 'store', params.data._id, undefined, true),
        cellRenderer: (params: any) => {
          const locationInfoUrl = params.value;
          if (!locationInfoUrl) {
            return null;
          }
          return (
            <Button type='link' onClick={() => window.open(locationInfoUrl, '_blank')}>
              {locationInfoUrl}
            </Button>
          );
        },
      },
      {
        field: 'accessInfo',
        headerName: '*출입 정보',
        editable: true,
        cellEditorPopup: true,
        cellEditor: (params: any) => {
          const { _id } = params.data;
          const value = params.value as AccessInfo[] | null;
          return <AccessInfoCellEditor storeId={_id} value={value} stopEditing={params.stopEditing} />;
        },
        cellRenderer: (params: any) => {
          const value = params.value as AccessInfo[] | null;
          return value ? value.map((v, i) => <AcccessInfoTag key={i + v.method} accessInfo={v} />) : null;
        },
      },
      {
        field: 'memo',
        headerName: '*배송 특이사항',
        wrapText: true,
        autoHeight: true,
        cellStyle: { whiteSpace: 'pre-wrap', lineHeight: '1.5', padding: 8 } as any,
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        onCellValueChanged: (params) => onCellValueChanged(params, 'store', params.data._id, undefined, true),
      },
      {
        field: 'paymentVendor',
        headerName: '*결제처',
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: paymentVendors,
        },
        onCellValueChanged: (params) => onCellValueChanged(params, 'store', params.data._id),
      },
      {
        field: 'paymentMethod',
        headerName: '*결제방식',
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: paymentMethods,
        },
        cellStyle: cellStyleForPaymentMethod,
        onCellValueChanged: (params) => onCellValueChanged(params, 'store', params.data._id),
      },
      {
        field: 'businessName',
        headerName: '*상호명',
        editable: true,
        onCellValueChanged: (params) => onCellValueChanged(params, 'store', params.data._id),
      },
      {
        field: 'businessNumber',
        headerName: '*사업자번호',
        editable: true,
        onCellValueChanged: (params) => onCellValueChanged(params, 'store', params.data._id),
      },
      {
        field: 'businessAddress',
        headerName: '*사업자주소',
        editable: true,
        onCellValueChanged: (params) => onCellValueChanged(params, 'store', params.data._id),
      },
      {
        field: 'ownerName',
        headerName: '*대표',
        editable: true,
        onCellValueChanged: (params) => onCellValueChanged(params, 'store', params.data._id),
      },
      {
        field: 'ownerBirthday',
        headerName: '*대표생년월일',
        editable: true,
        onCellValueChanged: (params) => onCellValueChanged(params, 'store', params.data._id),
      },
      {
        field: 'businessStatus',
        headerName: '사업자등록상태',
      },
      {
        field: 'taxType',
        headerName: '과세유형',
      },
      {
        field: 'endDate',
        headerName: '폐업일',
        valueFormatter: ({ value }) => (value ? value.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : ''),
      },
      {
        headerName: '액션',
        hide: true,
        cellRenderer: DownloadRawDataCell,
      },
    ];
  }, [avgForThisMonth, deliverySpots, ordersForThisMonth]);

  return (
    <>
      <div className='tableButtons'>
        <Button onClick={() => saveGridState(gridRef, StoreTable.name)}>설정 저장</Button>
        <Button onClick={() => loadGridState(gridRef, StoreTable.name)}>설정 불러오기</Button>
        <Button onClick={() => resetGridState(gridRef, StoreTable.name)}>설정 초기화</Button>
      </div>
      <div className={classes.storeTableContainer}>
        <header>
          <div>
            <HelpModal
              title='매장 관리'
              content={
                <div>
                  <p>
                    <b>관리자 전화번호:</b> 사용자가 가입시 입력한 연락처가 카톡 수신이 불가능한 경우, 수신 가능한 대체
                    번호를 입력하는 필드입니다.
                  </p>
                  <br />
                  <p>
                    <b>배송비 부과: </b> on으로 하는 경우 배송비 부과대상에 포함되며 {`${"'설정'"}`}페이지의 배송비
                    규칙이 적용됩니다.
                  </p>
                </div>
              }
            />
            <span>
              매장 수: {rowData.length} / {rowData0.length}
            </span>
          </div>
          <div>
            <Checkbox checked={avgForThisMonth} onChange={onChangeAvgForThisMonth}>
              이번달 평균 단가 표시
            </Checkbox>
            <Checkbox checked={storeCodeFilter} onChange={onChangeStoreCodeFilter}>
              미등록 & 테스트 매장 제외
            </Checkbox>
            <Checkbox checked={storeStateFilter} onChange={onChangeStoreStateFilter}>
              운영 매장만 표기
            </Checkbox>
          </div>
        </header>
        <section className='ag-theme-alpine'>
          <AgGridReact
            ref={setGridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={() => loadGridState(gridRef, StoreTable.name)}
            defaultColDef={defaultColDef}
            // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
            getRowId={(params) => params.data._id}
            context={{ user, ordersForStore: groupByStore(ordersForThisMonth), deliverySpots }}
            // 편집 완료후 스크롤 이동을 막는다.
            suppressScrollOnNewData={true}
            alwaysShowHorizontalScroll={true}
            getRowStyle={getRowStyle}
          />
        </section>
      </div>
    </>
  );
};

export default StoreTable;
