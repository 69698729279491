import { LoadingOutlined } from '@ant-design/icons';
import { FC } from 'react';

import useLogOrdersByOrderId from 'src/hooks/useLogOrdersByOrderId';
import useOrderById from 'src/hooks/useOrderById';

import LogOrderView from 'src/atomic-components/molecules/OrderView/LogOrderView';
import OrderView from 'src/atomic-components/molecules/OrderView/OrderView';

import classes from './OrderAndLogView.module.scss';

interface OrderAndLogViewProps {
  orderId: string;
}

const OrderAndLogView: FC<OrderAndLogViewProps> = ({ orderId }) => {
  const { order, orderLoading } = useOrderById(orderId);
  const { logOrders, logOrdersLoading } = useLogOrdersByOrderId(orderId);

  return (
    <div className={classes.sectionContainer}>
      <section>
        <h3>현재 주문</h3>
        {orderLoading ? <LoadingOutlined /> : order ? <OrderView order={order} /> : '주문 정보가 없습니다.'}
      </section>
      <section>
        <h3>주문 변동 내역</h3>
        {logOrdersLoading ? (
          <LoadingOutlined />
        ) : logOrders && logOrders.length > 0 ? (
          <LogOrderView logOrders={logOrders} />
        ) : (
          '주문 변동 내역이 없습니다.'
        )}
      </section>
    </div>
  );
};

export default OrderAndLogView;
