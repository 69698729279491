import { AccessMethod } from '@gooduncles/gu-app-schema';
import versionJson from 'src/version.json';

export const isProduction = process.env.REACT_APP_PROJECT_ID === 'gooduncles-10f09';

export const version = isProduction ? versionJson.prod : versionJson.dev;

export const logArt = 'gooduncles';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID === 'null' ? undefined : process.env.REACT_APP_MEASUREMENT_ID,
};

export const senderKey = process.env.REACT_APP_NHN_NOTIFICATION_SENDERKEY;

/**
 * 기타 상품코드
 */
export const EtcProductCode = 'P10000';

export const imageBaseUrl = 'https://firebasestorage.googleapis.com/v0/b/gooduncles-10f09.appspot.com/o/gu-files';

/** 매장 - 업종 */
export const storeCategories = {
  salad_cafe: '샐러드·카페',
  korean: '한식',
  barbeque_jokbal: '고기·족발·보쌈',
  chicken_pizza: '치킨·피자',
  japanese: '일식·돈까스·회',
  western: '양식',
  chinese: '중식',
  asian: '아시안',
  fastfood: '버거',
  snack: '분식',
  all: '전체',
  etc: '기타',
};
export type StoreCategory = keyof typeof storeCategories;

/** 상품 - 종목 */
export const productCategories: { [key: string]: string } = {
  custom: '맞춤상품',
  vegetable: '채소',
  fruit: '과일',
  grain: '쌀/곡물류',
  livestock: '축산/계란',
  processed: '가공식품',
  marine: '수산/건어물',
  grocery: '잡화',
  preCutVegetable: '전처리채소',
  unknown: '없음',
};

/** 상품 - 종목(Kr to En) */
export const productCategoriesForKr: { [key: string]: string } = {
  맞춤상품: 'custom',
  채소: 'vegetable',
  과일: 'fruit',
  '쌀/곡물류': 'grain',
  '축산/계란': 'livestock',
  가공식품: 'processed',
  '수산/건어물': 'marine',
  잡화: 'grocery',
  전처리채소: 'preCutVegetable',
  없음: 'unknown',
};

export type ProductCategory = keyof typeof productCategories;

/** 상품 - 판매 상태 */
export const productStates = {
  stock: '🟢 재고있음',
  'out-of-stock': '🟡 재고없음',
  discontinued: '🔴 판매중지',
};

export type ProductState = keyof typeof productStates;

/** 상품 - 물품 보관 정보 */
export const productStorages: Record<string, string> = {
  room: '실온',
  refrigerator: '냉장',
  freezer: '냉동',
};

export type ProductStorage = keyof typeof productStorages;

/** 상품 - 패킹 정보 */
export const productPackings: Record<string, string> = {
  bag: '🧺 소분',
  box: '📦 박스',
  others: '기타',
};

export type ProductPacking = keyof typeof productPackings;

export const requiredRule = [{ required: true, message: '필수 항목입니다!' }];

export const orderStatusKr: Record<number, string> = {
  /** SUBMITTED */
  10: '⚪️ 수락대기',
  /** ACCEPTED */
  20: '🟡 주문수락',
  /** DELIVERED */
  50: '🟢 배송완료',
  /** CANCELED */
  60: '⚫️ 주문취소',
  /** REJECTED */
  70: '🔴 주문거절',
};

export const orderStatusEn = {
  10: 'submitted',
  20: 'accepted',
  50: 'delivered',
  60: 'canceled',
  70: 'rejected',
};

export const editOrderErrors = {
  signup: '회원 가입이 필요합니다. 회원가입 바로가기',
  onEdit: '수정을 누른 후 변경할 수 있습니다.',
};

export const storeStateList = {
  guest: '승인대기',
  open: '운영',
  break: '휴업',
  closed: '폐업',
};
export type StoreState = keyof typeof storeStateList;

export type UserRole = 'admin' | 'manager' | 'user' | 'unknown';
export const userRoleList: UserRole[] = ['admin', 'manager', 'user'];
export const userRoleKr: Record<UserRole, string> = {
  admin: '관리자',
  manager: '매니저',
  user: '일반 사용자',
  unknown: '알 수 없음',
};

export const enum LocalStorageKey {
  deviceInfo = 'deviceInfo',
  storeIssuePrint = 'store-issue-print-table-filter-model',
  productRequestTalbeFilterModel = 'product-request-table-filter-model',
  productRequestTalbeColumnState = 'product-request-table-column-state',
  memoWidth = 'memo-width',
  temporaryDeliveryRoute = 'temporary-delivery-route',
}

export const CommerceIssueSubCategory: Record<string, string[]> = {
  refund: ['파손', '미배송', '불량', '반품', '차액', '기타'],
  order: ['추가주문', '차액추가'],
  discount: ['쿠폰', '기타'],
  delivery: ['배송누락', '기타'],
};

export const commerceIssueStatusKr: Record<string, string> = {
  new: '대기',
  pending: '입력(고객노출)',
  pendingIn: '입력(내부용)',
  ongoing: '확인중',
  resolved: '완료',
  deleted: '삭제',
  hold: '보류',
};

export const billDetailAmountMappings: Record<string, string> = {
  총금액: 'totalAmount',
  공급가: 'supplyAmount',
  세액: 'tax',
};

export const rawProductStorageToCode: Record<string, ProductStorage> = {
  '1실온': 'room',
  '2냉장': 'refrigerator',
  '3냉동': 'freezer',
};

export const rawProductPackingToCode: Record<string, ProductPacking> = {
  '1소분': 'bag',
  '2박스': 'box',
  '3기타': 'others',
};

export const PRODUCT_MAX_STOCK = Infinity;

export const FEATURES_WITH_PRIORITY = ['박스', '냉동', '냉장'];

/**
 * 정산이 완료된 주문인지를 확인하는 테이블
 * 수정하는 당시의 요일이 배열의 index에 해당하고,
 * 주문 날짜의 요일이 해당 value에 해당하는 요일이라면 미정산 단계로 수정이 가능하다.
 *
 */
export const settlementDayTable = [
  // 현재 요일 - 수정 가능한 요일
  // 일 - 목, 금, 토, 일
  [4, 5, 6, 0],
  // 월 - 목, 금, 토, 일, 월
  [0, 1, 4, 5, 6],
  // 화 - 월, 화
  [1, 2],
  // 수 - 월, 화, 수
  [1, 2, 3],
  // 목 - 월, 화, 수, 목
  [1, 2, 3, 4],
  // 금 - 목, 금
  [4, 5],
  // 토 - 목, 금, 토
  [4, 5, 6],
];

/**
 * 고객노출 이슈내용의 말머리를 채우기 위한 테이블
 */
export const commerceIssueCategoryTable: Record<string, string> = {
  배송: '★배송-',
  회수: '☆회수-',
  보관: '※보관-',
  식당환불: '※환불-',
  주문수정: '※주문수정-',
  매입처반품: '※매입처반품-',
  매입처매입: '※매입처매입-',
  매입자료수정: '※매입자료수정-',
  매출자료수정: '※매출자료수정-',
  쿠폰할인: '※쿠폰할인-',
  주문: '※주문-',
  기타: '※기타-',
};

export const userStatusKr: Record<string, string> = {
  active: '🟢 활성',
  inactive: '🔴 비활성',
  deleted: '⚫️ 삭제',
};

/**
 * 통계 자료에서 사용할 수 있는 연도
 */
export const availableYearsForStats = {
  2022: '2022',
  2023: '2023',
  2024: '2024',
};
export type AvailableYearForStats = keyof typeof availableYearsForStats;

export const weekdayToKr: Record<string, string> = {
  sun: '일',
  mon: '월',
  tue: '화',
  wed: '수',
  thu: '목',
  fri: '금',
  sat: '토',
};

export const purchaseMethodToKr: Record<string, string> = {
  kakaotalk: '카톡',
  sms: '문자',
  call: '전화',
  api: '전산',
  visit: '방문',
  office: '사무실',
  etc: '기타',
};

export const purchaseBundleToKr: Record<string, string> = {
  max: '최대 묶음',
  all: '전체',
  store: '매장별',
  storeGroup: '매장별 수량 묶음',
};

/**
 * 굿엉클스 하남 감북동 창고 좌표
 */
export const goodunclesCoords = {
  // 위도
  lat: 37.52342649839478,
  // 경도
  lng: 127.16741757398434,
};

/**
 * 서울시 좌표 범위
 */
export const seoulCoordsRange = {
  lat: {
    min: 37.413294,
    max: 37.715133,
  },
  lng: {
    min: 126.734086,
    max: 127.269311,
  },
};

/**
 * SMS 문자 발신 번호
 */
export const sendNo = '15332537';

/** 패킹시트와 거래명세서의 상품 정렬순서 */
export const packingOrder = ['bag', 'box', 'others'];
/** 패킹시트와 거래명세서의 상품 정렬순서 */
export const storageOrder = ['room', 'refrigerator', 'freezer', null];

/** 파트너스의 업무 유형 KR */
export const taskTypeToKr: Record<string, string> = {
  delivery: '배송',
  return: '회수',
  purchase: '매입',
};

/** 파트너스 기기의 권한 */
export const permissionsKrMap: Record<string, string> = {
  camera: '카메라',
  location: '위치',
  notification: '알림',
  photo: '사진첩',
};

export const permissionStateBall: Record<string, string> = {
  granted: '🟢',
  limited: '🟡',
};

/**
 * 출입 방법 중 사전 체크해야하는 키 유형
 */
export const requiredKeyList: AccessMethod[] = ['cardKey', 'key', 'fingerprint'];
