import {
  DeliveryRouteHistory,
  DeliveryRouteHistoryDoc,
  DeliverySpotDoc,
  DeliveryTask,
  LocationType,
  OrderDoc,
  PickupTask,
  StoreDoc,
  StoreIssueDoc,
  TaskItemStatus,
  TaskOrderCollection,
  TaskPackageMemo,
  TaskType,
} from '@gooduncles/gu-app-schema';
import { message, notification } from 'antd';
import { groupBy } from 'lodash-es';
import { DndCardItem, DndColumnData } from 'src/schema/schema-drag-and-drop';

import { deleteTimestamp, errorObjectToString } from 'src/lib/1/util';
import {
  batchEnd,
  batchStart,
  deleteDeliveryTask,
  deletePickupTask,
  getDeliveryTasks,
  getPickupTasks,
  setDeliveryRouteHistory,
  setDeliveryTask,
  setPickupTask,
  softDeleteDeliveryRouteHistory,
  updateOrder,
  updatePartnersUserColor,
  updateStoreIssue,
} from 'src/lib/4/firebase-short-cut';

import { convertOrderProductsToTaskItems } from './task-util';

const warehouseLocation = {
  locationType: LocationType.Warehouse,
  locationGroupId: 'warehouse', // 굿엉클스 창고
  locationId: 'warehouse', // 굿엉클스 창고
  locationName: '굿엉클스 창고',
  locationGroupSortKey: 9999,
};

const unregisteredLocation = {
  locationType: LocationType.Warehouse,
  locationGroupId: 'unregistered', // 굿엉클스 창고
  locationId: 'unregistered', // 굿엉클스
  locationName: 'unregistered',
  locationGroupSortKey: 9999,
};

export interface TaskSpot {
  /** 배정된 파트너의 id */
  partnerId: string;
  deliverySpotId: string;
  deliverySpot: DeliverySpotDoc | undefined;
  /** 배송지에 기본으로 등록되어있는 파트너의 id(배차 카드의 색상을 결정할때 사용된다.) */
  spotDefaultPartnerId: string;
  stores: TaskStore[];
}

interface TaskStore {
  storeId: string;
  store: StoreDoc | undefined;
  /**
   * 하나의 매장에 주문은 하나지만, 이슈가 추가될 수 있으며 이슈의 경우 복수가 될 수 있다.
   */
  taskBundles: TaskBundle[];
}

/**
 * TaskBundle은 주문과 매장이슈를 기준으로 생성된 픽업과 배송 업무 데이터 묶음이다.
 */
interface TaskBundle {
  /** 주문 또는 매장이슈의 id */
  orderId: string;
  orderColleciton: TaskOrderCollection;
  pickupTask: PickupTask;
  deliveryTask: DeliveryTask;
  data: OrderDoc | StoreIssueDoc;
}

/**
 * Order와 StoreIssue를 기준으로 픽업과 배송 업무 데이터를 생성한다.
 */
export const createTaskSpots = (
  date: string,
  deliveryRouteHistory0: DeliveryRouteHistoryDoc | undefined,
  stores: StoreDoc[],
  defaultDeliverySpots: DeliverySpotDoc[],
  orders: OrderDoc[],
  storeIssues: StoreIssueDoc[],
  temporaryDeliveryRouteHistory: DeliveryRouteHistoryDoc | null
) => {
  // 0. 임시저장한 내용이 있는 경우 해당 내용을 배송 동선에 병합합니다.
  const deliveryRouteHistory = deliveryRouteHistory0 ? deliveryRouteHistory0 : temporaryDeliveryRouteHistory;
  if (!deliveryRouteHistory0 && temporaryDeliveryRouteHistory) {
    message.info('임시 저장한 배송 동선을 불러왔습니다.');
  }
  // 1. 이미 생성한 동선이 있는 경우 해당 내용을 배송 동선에 병합합니다.
  const deliverySpots = deliveryRouteHistory
    ? defaultDeliverySpots.map((spot) => {
        const currentSpot = deliveryRouteHistory.deliverySpots.find((currentSpot) => currentSpot._id === spot._id);
        return currentSpot
          ? {
              ...spot,
              sortKey: currentSpot.sortKey,
              partnerId: currentSpot.partnerId,
            }
          : spot;
      })
    : defaultDeliverySpots;

  // 2. 주문별 픽업과 배송 업무를 생성한다.
  const orderTasks = orders.map((order) => {
    const store = stores.find((store) => store._id === order.storeId);
    const deliverySpot = deliverySpots.find((spot) => spot._id === store?.deliverySpotId);
    const deliveryLocation = store
      ? {
          locationType: LocationType.Store,
          locationGroupId: store.deliverySpotId,
          locationGroupSortKey: deliverySpot?.sortKey ?? 0,
          locationId: store._id,
          locationName: store.storeNickname,
        }
      : unregisteredLocation;
    const taskBase = {
      date,
      orderColleciton: TaskOrderCollection.Order,
      orderId: order._id,
      taskType: TaskType.Delivery,
      finishedAt: null,
      cancelReason: null,
      finishedCoords: null,
      partnerId: deliverySpot?.partnerId ?? 'undefined',
    };
    const taskItems = convertOrderProductsToTaskItems(order.products);
    const pickupTask: PickupTask = {
      _id: `${taskBase.partnerId}-${taskBase.orderColleciton}-${taskBase.orderId}`,
      ...taskBase,
      ...warehouseLocation,
      deliveryLocation,
      pickupItems: taskItems,
    };
    const packageMemo: TaskPackageMemo = {
      box: 0,
      bagOfOnions: 0,
      greenOnion: 0,
      otherPackage: 0,
    };
    const deliveryTask: DeliveryTask = {
      _id: `${taskBase.partnerId}-${taskBase.orderColleciton}-${taskBase.orderId}`,
      ...taskBase,
      ...deliveryLocation,
      deliveryItems: taskItems,
      deliveryImages: null,
      taskIssue: null,
      deletedDeliveryImages: null,
      pushNotificationId: null,
      packageMemo,
    };
    return {
      pickupTask,
      deliveryTask,
      orderColleciton: taskBase.orderColleciton,
      orderId: taskBase.orderId,
      store,
      deliverySpot,
      data: order,
    };
  });

  // 3. 매장이슈별 픽업과 배송 업무를 생성한다.
  const storeIssueTasks = storeIssues.map((storeIssue) => {
    const taskType = storeIssue.category === '회수' ? TaskType.Return : TaskType.Delivery;
    const store = stores.find((store) => store.storeCode === storeIssue.storeCode);
    const deliverySpot = deliverySpots.find((spot) => spot._id === store?.deliverySpotId);
    const storeLocaiton = store
      ? {
          locationType: LocationType.Store,
          locationGroupId: store.deliverySpotId,
          locationGroupSortKey: deliverySpot?.sortKey ?? 0,
          locationId: store._id,
          locationName: store.storeNickname,
        }
      : unregisteredLocation;
    const deliveryLocation = taskType === TaskType.Return ? warehouseLocation : storeLocaiton;
    const pickupLocation = taskType === TaskType.Return ? storeLocaiton : warehouseLocation;
    const taskBase = {
      date,
      orderColleciton: TaskOrderCollection.StoreIssue,
      orderId: storeIssue._id,
      partnerId: deliverySpot?.partnerId ?? 'undefined',
      taskType,
      finishedAt: null,
      cancelReason: null,
      finishedCoords: null,
    };
    const taskItems = {
      [storeIssue._id]: {
        productId: storeIssue._id,
        itemStatus: TaskItemStatus.Assigned,
        issue: null,
      },
    };
    const pickupTask: PickupTask = {
      _id: `${taskBase.partnerId}-${taskBase.orderColleciton}-${taskBase.orderId}`,
      ...taskBase,
      ...pickupLocation,
      deliveryLocation,
      pickupItems: taskItems,
    };
    const packageMemo: TaskPackageMemo = {
      box: 0,
      bagOfOnions: 0,
      greenOnion: 0,
      otherPackage: 0,
    };
    const deliveryTask: DeliveryTask = {
      _id: `${taskBase.partnerId}-${taskBase.orderColleciton}-${taskBase.orderId}`,
      ...taskBase,
      ...deliveryLocation,
      deliveryItems: taskItems,
      deliveryImages: null,
      taskIssue: null,
      deletedDeliveryImages: null,
      pushNotificationId: null,
      packageMemo,
    };
    return {
      pickupTask,
      deliveryTask,
      orderColleciton: taskBase.orderColleciton,
      orderId: taskBase.orderId,
      store,
      deliverySpot,
      data: storeIssue,
    };
  });

  // 4. 동선을 위해 두 번의 그룹화를 수행한다.
  const groupByDeliverySpot = groupBy(
    [...orderTasks, ...storeIssueTasks],
    (task) => task.deliverySpot?._id ?? 'unregistered'
  );
  const taskSpots: TaskSpot[] = Object.entries(groupByDeliverySpot).map(([deliverySpotId, tasks]) => {
    const groupByStoreId = groupBy(tasks, 'store._id');
    const stores: TaskStore[] = Object.entries(groupByStoreId).map(([storeId, tasks]) => {
      const store = tasks[0].store;
      const taskBundles: TaskBundle[] = tasks.map(({ pickupTask, deliveryTask, orderColleciton, orderId, data }) => ({
        orderId,
        orderColleciton,
        pickupTask,
        deliveryTask,
        data,
      }));
      const taskStore: TaskStore = {
        storeId,
        store,
        taskBundles,
      };
      return taskStore;
    });

    const deliverySpot = tasks[0].deliverySpot;
    const spotDefaultPartnerId =
      defaultDeliverySpots.find((spot) => spot._id === deliverySpotId)?.partnerId ?? 'undefined';
    const taskSpot: TaskSpot = {
      partnerId: deliverySpot?.partnerId ?? 'undefined',
      deliverySpotId,
      deliverySpot,
      stores,
      spotDefaultPartnerId,
    };
    return taskSpot;
  });

  return taskSpots;
};

type TypedDndCardItem = Omit<DndCardItem, 'data'> & {
  data: TaskSpot;
};

/**
 * 배송 동선 데이터를 생성한다.
 */
export const createDeliveryRouteHistoryData = (date: string, items: TypedDndCardItem[]): DeliveryRouteHistory => {
  const groupByPartnerId = groupBy(items, 'groupId');
  const deliverySpots: DeliverySpotDoc[] = [];
  for (const [index, itemsForPartner] of Object.values(groupByPartnerId).flat().entries()) {
    const deliverySpot0 = itemsForPartner.data.deliverySpot;
    if (!deliverySpot0) continue;
    const deliverySpot = deleteTimestamp<DeliverySpotDoc>(deliverySpot0);
    const sortKey = index + 1;
    const partnerId = itemsForPartner.groupId;
    const newDeliverySpot: DeliverySpotDoc = {
      ...deliverySpot,
      partnerId,
      sortKey,
    };
    deliverySpots.push(newDeliverySpot);
  }
  return {
    date,
    deliverySpots,
    isDeleted: false,
  };
};

/**
 * 물류 업무 데이터를 생성한다.
 */
export const createTaskData = (items: TypedDndCardItem[]) => {
  return items.reduce<{ pickupTasks: PickupTask[]; deliveryTasks: DeliveryTask[] }>(
    (acc, item, index) => {
      const partnerId = item.groupId;
      const pickupTasks = item.data.stores
        .flatMap((store) => store.taskBundles)
        .map((taskBundle) => ({
          ...taskBundle.pickupTask,
          _id: `${partnerId}-${taskBundle.pickupTask.orderColleciton}-${taskBundle.pickupTask.orderId}`,
          partnerId,
          locationGroupSortKey: index + 1,
        }));
      const deliveryTasks = item.data.stores
        .flatMap((store) => store.taskBundles)
        .map((taskBundle) => ({
          ...taskBundle.deliveryTask,
          _id: `${partnerId}-${taskBundle.deliveryTask.orderColleciton}-${taskBundle.deliveryTask.orderId}`,
          partnerId,
          locationGroupSortKey: index + 1,
        }));
      return {
        pickupTasks: [...acc.pickupTasks, ...pickupTasks],
        deliveryTasks: [...acc.deliveryTasks, ...deliveryTasks],
      };
    },
    { pickupTasks: [], deliveryTasks: [] }
  );
};

/**
 * 주문, 이슈에 업무 정보를 업데이트를 위한 데이터를 생성한다.
 */
export const createUpdateOrderData = (items: TypedDndCardItem[], columns: DndColumnData[]) => {
  return items.reduce<{
    orders: Partial<OrderDoc>[];
    storeIssues: Partial<StoreIssueDoc>[];
  }>(
    (acc, item) => {
      const partnerId = item.groupId;
      const partnerName = columns.find((column) => column.id === partnerId)?.title ?? '?';
      const itemOrders = item.data.stores.flatMap((store) =>
        store.taskBundles
          .filter((taskBundle) => taskBundle.orderColleciton === TaskOrderCollection.Order)
          .map((taskBundle) => ({
            _id: taskBundle.orderId,
            deliveryPartnerId: partnerId,
            deliveryPartnerName: partnerName,
            deliverySpotId: taskBundle.deliveryTask.locationGroupId,
            pickupPartnerId: partnerId,
            pickupPartnerName: partnerName,
          }))
      );

      const itemStoreIssues = item.data.stores.flatMap((store) =>
        store.taskBundles
          .filter((taskBundle) => taskBundle.orderColleciton === TaskOrderCollection.StoreIssue)
          .map((taskBundle) => ({
            _id: taskBundle.orderId,
            deliveryPartnerId: partnerId,
            deliveryPartnerName: partnerName,
            deliverySpotId: taskBundle.deliveryTask.locationGroupId,
            pickupPartnerId: partnerId,
            pickupPartnerName: partnerName,
          }))
      );

      return {
        orders: [...acc.orders, ...itemOrders],
        storeIssues: [...acc.storeIssues, ...itemStoreIssues],
      };
    },
    { orders: [], storeIssues: [] }
  );
};

/**
 * 주문, 이슈의 업무 정보를 초기화한다.
 */
export const resetTasksOnOrder = (items: TypedDndCardItem[]) => {
  return items.reduce<{
    orders: Partial<OrderDoc>[];
    storeIssues: Partial<StoreIssueDoc>[];
  }>(
    (acc, item) => {
      const itemOrders = item.data.stores.flatMap((store) =>
        store.taskBundles
          .filter((taskBundle) => taskBundle.orderColleciton === TaskOrderCollection.Order)
          .map((taskBundle) => ({
            _id: taskBundle.orderId,
            deliveryPartnerId: null,
            deliveryPartnerName: null,
            deliverySpotId: null,
            pickupPartnerId: null,
            pickupPartnerName: null,
          }))
      );

      const itemStoreIssues = item.data.stores.flatMap((store) =>
        store.taskBundles
          .filter((taskBundle) => taskBundle.orderColleciton === TaskOrderCollection.StoreIssue)
          .map((taskBundle) => ({
            _id: taskBundle.orderId,
            deliveryPartnerId: null,
            deliveryPartnerName: null,
            deliverySpotId: null,
            pickupPartnerId: null,
            pickupPartnerName: null,
          }))
      );

      return {
        orders: [...acc.orders, ...itemOrders],
        storeIssues: [...acc.storeIssues, ...itemStoreIssues],
      };
    },
    { orders: [], storeIssues: [] }
  );
};

/**
 * 배차 확정을 수행한다.
 */
export const confirmDeliveryRoute = async (date: string, items: TypedDndCardItem[], columns: DndColumnData[]) => {
  // 1. 데이터 준비
  // 1-1. 배송 동선 데이터를 생성한다.
  const deliveryRouteHistory = createDeliveryRouteHistoryData(date, items);

  // 1-2. 업무를 생성한다.
  const { pickupTasks, deliveryTasks } = createTaskData(items);
  const currentPickupTasks = await getPickupTasks([['date', '==', date]]);
  const currentDeliveryTasks = await getDeliveryTasks([['date', '==', date]]);

  // 1-3. 주문, 이슈에 업무 정보를 붙입니다.
  const { orders, storeIssues } = createUpdateOrderData(items, columns);

  // 2. 실 데이터 업데이트
  batchStart();
  // 2-1. 배송 동선을 업데이트한다.
  await setDeliveryRouteHistory(date, deliveryRouteHistory, {
    bBatch: true,
    bMerge: true,
  });
  message.info(`${date}일자 배송 동선을 생성했습니다.`);

  // 2-2. 업무를 업데이트 하기전 해당 날짜의 기존 업무를 모두 삭제한다.
  for (const iterator of currentPickupTasks) {
    await deletePickupTask(iterator._id, true);
  }
  for (const iterator of currentDeliveryTasks) {
    await deleteDeliveryTask(iterator._id, true);
  }
  // 2-3. 업무를 생성한다.
  for (const pickupTask of pickupTasks) {
    await setPickupTask(pickupTask, { bBatch: true, bMerge: true });
  }
  for (const deliveryTask of deliveryTasks) {
    await setDeliveryTask(deliveryTask, { bBatch: true, bMerge: true });
  }
  message.info(`${date}일자 업무를 생성했습니다.`);

  // 2-4. 주문, 이슈를 업데이트한다.
  for (const orderData of orders) {
    if (!orderData._id) continue;
    await updateOrder(orderData._id, orderData, true);
  }
  for (const storeIssueData of storeIssues) {
    if (!storeIssueData._id) continue;
    await updateStoreIssue(storeIssueData._id, storeIssueData, true);
  }
  message.info(`${date}일자 주문, 이슈를 업데이트했습니다.`);

  await batchEnd();
};

/**
 * 파트너의 지정 색상을 변경합니다.
 */
export const updatePartnerColor = async (partnerId: string, color: string) => {
  try {
    await updatePartnersUserColor(partnerId, color);
    message.success('색상을 변경했습니다.');
  } catch (error) {
    console.error(error);
    const description = errorObjectToString(error);
    notification.error({
      message: '색상 변경 실패',
      description,
    });
  }
};

/**
 * 확정된 배차 정보를 초기화합니다.
 * 1. 주문, 이슈의 업무 정보 초기화
 * 2. 해당 날짜의 업무 삭제
 * 3. 배송 동선 삭제
 */
export const resetDeliveryRoute = async (deliveryRouteId: string, date: string, items: TypedDndCardItem[]) => {
  // 1. 주문, 이슈의 업무 정보를 초기화 합니다.
  const { orders, storeIssues } = resetTasksOnOrder(items);

  // 2. 삭제할 업무를 불러옵니다.
  const currentPickupTasks = await getPickupTasks([['date', '==', date]]);
  const currentDeliveryTasks = await getDeliveryTasks([['date', '==', date]]);

  batchStart();
  await softDeleteDeliveryRouteHistory(deliveryRouteId, true);
  // 2-2. 해당 날짜의 기존 업무를 모두 삭제한다.
  for (const iterator of currentPickupTasks) {
    await deletePickupTask(iterator._id, true);
  }
  for (const iterator of currentDeliveryTasks) {
    await deleteDeliveryTask(iterator._id, true);
  }
  // 2-3. 주문, 이슈를 업데이트한다.
  for (const orderData of orders) {
    if (!orderData._id) continue;
    await updateOrder(orderData._id, orderData, true);
  }
  for (const storeIssueData of storeIssues) {
    if (!storeIssueData._id) continue;
    await updateStoreIssue(storeIssueData._id, storeIssueData, true);
  }
  await batchEnd();
};
