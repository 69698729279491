import { AccessInfo, AccessMethod } from '@gooduncles/gu-app-schema';
import { Popover, Tag } from 'antd';
import { FC } from 'react';

import card from 'src/assets/images/card-200.png';
import fingerprint from 'src/assets/images/fingerprint-200.png';
import key from 'src/assets/images/key-200.png';
import noKey from 'src/assets/images/no-key-200.png';
import numberKey from 'src/assets/images/number-key-200.png';
import passwordLock from 'src/assets/images/password-lock-200.png';
import secretKey from 'src/assets/images/secret-key-200.png';

const accessMethodImageMap: Record<AccessMethod, string> = {
  cardKey: card,
  key: key,
  keypad: numberKey,
  codeLock: passwordLock,
  hiddenKey: secretKey,
  fingerprint: fingerprint,
  noKey: noKey,
};

interface AccessInfoTagProps {
  accessInfo: AccessInfo;
  onClose?: () => void;
}

const AcccessInfoTag: FC<AccessInfoTagProps> = ({ accessInfo, onClose }) => {
  return (
    <Tag closable={!!onClose} onClose={onClose} style={{ backgroundColor: 'white' }}>
      <Popover content={accessInfo.details} trigger='hover'>
        <img
          src={accessMethodImageMap[accessInfo.method]}
          style={{
            width: '20px',
            height: '20px',
            verticalAlign: 'middle',
          }}
        />
        <span
          style={{
            maxWidth: '100px',
            display: 'inline-block',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {accessInfo.details ? `: ${accessInfo.details}` : ''}
        </span>
      </Popover>
    </Tag>
  );
};

export default AcccessInfoTag;
