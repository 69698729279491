import { Tabs } from 'antd';
import { differenceWith, isEqual } from 'lodash-es';
import { FC } from 'react';

import { userRoleKr } from 'src/lib/1/constant';
import { formatDate } from 'src/lib/1/date-util';
import { formatNumber } from 'src/lib/1/util';
import { LogDoc, LogOrder } from 'src/lib/3/schema-log';

import CollapsibleList from 'src/atomic-components/atoms/CollapsibleList/CollapsibleList';
import ListRow from 'src/atomic-components/atoms/ListRow/ListRow';

import classes from './OrderView.module.scss';

interface LogOrderViewProps {
  logOrders: LogDoc<LogOrder>[];
}

const LogOrderView: FC<LogOrderViewProps> = ({ logOrders }) => {
  const user = logOrders?.[0]?.body.user;

  const tabItems = logOrders.map((logOrder) => {
    const {
      _id,
      body: { before, after, reason, reasonForUser },
    } = logOrder;

    const diff = differenceWith(before.products, after.products, isEqual);
    const diffProductIds = diff.map((product) => product.productId);

    return {
      key: _id,
      label: formatDate(logOrder._timeCreate.toDate(), 'HH:mm:ss'),
      children: (
        <div className={classes.orderViewContent} key={_id}>
          <CollapsibleList id={_id + 'info'} label='주문 수정 정보' defaultOpen={true}>
            <ListRow label='수정 내용' title={reason} />
            <ListRow label='고객 안내' title={reasonForUser ?? '-'} />
            <ListRow title='주문 수정 시각' detail={formatDate(logOrder._timeCreate.toDate(), 'yyyy-MM-dd HH:mm:ss')} />
            <ListRow
              title='총 합계(전 → 후)'
              detail={
                <>
                  {formatNumber(before.grandTotal)} →{' '}
                  <span className={classes.afterPrice}>{formatNumber(after.grandTotal)}</span>
                </>
              }
            />
          </CollapsibleList>

          {user && (
            <CollapsibleList id={user._id} label='수정한 사용자' defaultOpen={true}>
              <ListRow title='이메일' detail={user.email} />
              <ListRow title='계정 등급' detail={userRoleKr[user.role]} />
            </CollapsibleList>
          )}

          <CollapsibleList id={_id} label='수정 전 주문 상품' defaultOpen={true}>
            <ListRow key='deliveryFee' title='배송비' detail={formatNumber(before.deliveryFee ?? 0)} />
            {before.products.map((product) => {
              const afterProduct = diffProductIds.includes(product.productId)
                ? after.products.find((p) => p.productId === product.productId)
                : undefined;
              return (
                <ListRow
                  key={product.productId}
                  title={
                    <span
                      style={{
                        backgroundColor: afterProduct ? 'var(--yellow300)' : 'inherit',
                      }}>
                      {product.fullName}
                    </span>
                  }
                  subtitle={
                    <>
                      <span>{`${formatNumber(product.price)} x ${product.volume ?? 1}`}</span>
                      {afterProduct && (
                        <span className={classes.changeVolume}>
                          ({product.volume} → {afterProduct.volume > 0 ? afterProduct.volume : '품절'})
                        </span>
                      )}
                    </>
                  }
                  detail={
                    <span style={{ color: afterProduct ? 'var(--red400)' : 'inherit' }}>
                      {formatNumber(
                        product.price * (afterProduct?.volume !== undefined ? afterProduct.volume : product.volume ?? 1)
                      )}
                    </span>
                  }
                />
              );
            })}
          </CollapsibleList>
        </div>
      ),
    };
  });

  return <Tabs defaultActiveKey={tabItems[0].key ?? undefined} items={tabItems} />;
};

export default LogOrderView;
