import {
  DailyProductForPDF,
  PackingInfoProductEntry,
  PaperInvoice,
  ProductsForSupplierEntry,
} from '@gooduncles/gu-app-schema';
import jsPDF, { CellConfig } from 'jspdf';
import { groupBy } from 'lodash-es';

import { commerceIssueCategoryTable } from 'src/lib/1/constant';
import { formatDate } from 'src/lib/1/date-util';
import { SupplierDoc } from 'src/lib/3/schema-supplier';

import { myFont } from './font-for-jspdf';

const sortRules = [
  '배송',
  '회수',
  '식당환불',
  '매입처반품',
  '매입처매입',
  '주문수정',
  '매입자료수정',
  '매출자료수정',
  '기타',
  '주문',
  '쿠폰할인',
  '보관',
  'issue',
  'product',
];

/**
 * 거래명세서 데이터를 오늘의 주문 품목 PDF 데이터로 변환한다.
 */
const convertPaperInvoicesToDailyProduct = (paperInvoices: PaperInvoice[], exceptBag = false) => {
  // 1. convert orderProduct
  const products: DailyProductForPDF[] = paperInvoices
    .flatMap((invoice) => {
      const orderProducts = !exceptBag
        ? invoice.order?.products ?? []
        : (invoice.order?.products ?? []).filter((p) => p.packing !== 'bag');
      return orderProducts.map((product) => ({
        partnerKey: invoice.sortKey,
        storeName: invoice.store.storeNickname,
        label: product.fullName,
        quantity: String(product.volume),
        sortKey: 'product',
        id: product.productId,
      }));
    })
    // 담당자
    .sort((a, b) => a.partnerKey - b.partnerKey)
    // 수량(오름차순)
    .sort((a, b) => a.quantity.localeCompare(b.quantity))
    // 상품명
    .sort((a, b) => a.label.localeCompare(b.label));

  // 2. convert storeIssue
  const storeIssues: DailyProductForPDF[] = paperInvoices
    .flatMap((invoice) => {
      const issues = invoice.storeIssues ?? [];
      return issues.map((issue) => {
        const prefix = issue.category ? commerceIssueCategoryTable[issue.category] ?? '' : '';
        const label = issue.message ? prefix + issue.message : issue.memo;
        return {
          partnerKey: invoice.sortKey,
          storeName: invoice.store.storeNickname,
          label: label ?? '?',
          quantity: String(issue.volume ?? 0),
          sortKey: issue.category ?? 'issue',
          id: issue.productId ?? '',
        };
      });
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  return {
    products,
    storeIssues,
  };
};

export const downloadDailyProductList = async (date: string, paperInvoices: PaperInvoice[]) => {
  const { products, storeIssues } = convertPaperInvoicesToDailyProduct(paperInvoices);

  // 3. merge and sort
  const merged = [...products, ...storeIssues]
    .sort((a, b) => sortRules.indexOf(a.sortKey) - sortRules.indexOf(b.sortKey))
    .map((item) => {
      const { sortKey, id, ...item0 } = item;
      sortKey;
      id;
      return {
        ...item0,
        partnerKey: String(item.partnerKey),
      };
    });

  const filename = `${formatDate(date, 'yyMMdd')}_오늘의주문품목.pdf`;
  const doc = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4' });
  const headers: CellConfig[] = [
    {
      name: 'partnerKey',
      prompt: '담당자',
      width: 28,
      align: 'center',
      padding: 0,
    },
    {
      name: 'storeName',
      prompt: '매장명',
      width: 74,
      align: 'center',
      padding: 0,
    },
    {
      name: 'label',
      prompt: '품명',
      width: 150,
      align: 'center',
      padding: 0,
    },
    {
      name: 'quantity',
      prompt: '수량',
      width: 20,
      align: 'center',
      padding: 0,
    },
  ];

  doc.addFileToVFS('NotoSansKR-Medium-normal.ttf', myFont);
  doc.addFont('NotoSansKR-Medium-normal.ttf', 'NotoSansKR-Medium', 'bold');
  doc.addFont('NotoSansKR-Medium-normal.ttf', 'NotoSansKR-Medium', 'normal');
  doc.setFont('NotoSansKR-Medium', 'bold');
  doc.setFont('NotoSansKR-Medium', 'normal');
  doc.table(1, 1, merged, headers, {});
  doc.save(filename);
};

/**
 * 매입처로 정렬된 상품 목록을 생성한다.
 */
export const getProductListForSupplier = (paperInvoices: PaperInvoice[], suppliers: SupplierDoc[]) => {
  const productSupplierTable = Object.fromEntries(
    suppliers.map((s) => s.conversionTable.map((p) => [p.productId, s.label + s.name] as [string, string])).flat()
  );

  const { products, storeIssues } = convertPaperInvoicesToDailyProduct(paperInvoices, true);
  const productsWithSupplier = products.map((p) => {
    return {
      ...p,
      supplier: productSupplierTable[p.id],
    };
  });
  const storeIssuesWithSupplier = storeIssues
    .map((p) => {
      return {
        ...p,
        supplier: '기타',
      };
    })
    .sort((a, b) => sortRules.indexOf(a.sortKey) - sortRules.indexOf(b.sortKey));

  const merged = [...storeIssuesWithSupplier, ...productsWithSupplier];

  const productForSupplierEntry: ProductsForSupplierEntry = Object.fromEntries(
    Object.entries(groupBy(merged, 'supplier')).map(([supplier, products]) => {
      const groupByProductName = groupBy(products, 'label');
      const groupedList = Object.fromEntries(
        Object.entries(groupByProductName).map(([productName, products0]) => {
          const groupByPartnerKey = groupBy(products0, 'partnerKey');
          const groupedList = Object.fromEntries(
            Object.entries(groupByPartnerKey).map(([supplier, products1]) => {
              const sum = products1.reduce((acc, cur) => acc + Number(cur.quantity), 0);
              return [supplier, sum];
            })
          );
          return [productName, groupedList];
        })
      );
      return [supplier, groupedList];
    })
  );

  return productForSupplierEntry;
};

/**
 * 계란과 같은 특정 상품을 매장별 묶음 단위로 묶어서 정리한다.
 */
export const getPackingInfoProductList = (
  paperInvoices: PaperInvoice[],
  supplier: SupplierDoc,
  purchaseBundles: ('all' | 'store' | 'max' | 'storeGroup')[]
): PackingInfoProductEntry => {
  const ids = supplier.conversionTable
    .filter((item) => purchaseBundles.includes(item.purchaseBundle))
    .map((item) => item.productId);
  console.log(supplier.conversionTable);

  const specialProducts = paperInvoices
    .map((invoice) => {
      return (invoice.order?.products ?? []).map((p) => ({
        ...p,
        sortKey: invoice.sortKey,
        storeId: invoice.storeId,
      }));
    })
    .flat()
    .filter((p) => ids.includes(p.productId));

  // 1. 상품 별로 묶는다.
  const groupByProductId = groupBy(specialProducts, 'productId');
  // 2. 담당자 순서별로 묶는다.
  const groupByPartnerSortKey = Object.fromEntries(
    Object.entries(groupByProductId).map(([productId, products]) => {
      const groupBySortKey = groupBy(products, 'sortKey');
      return [productId, groupBySortKey];
    })
  );
  // 3. 수량별로 묶는다.
  const final = Object.fromEntries(
    Object.entries(groupByPartnerSortKey).map(([productId, group]) => {
      const list = Object.fromEntries(
        Object.entries(group).map(([sortKey, products]) => {
          const groupByVolume = groupBy(products, 'volume');
          const groupedList = Object.fromEntries(
            Object.entries(groupByVolume).map(([volume, products0]) => {
              return [volume, products0.length];
            })
          );
          return [sortKey, groupedList];
        })
      );
      const productName = specialProducts.find((p) => p.productId === productId)?.fullName ?? '';
      return [productName, list];
    })
  );

  return final;
};
