import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { map } from 'rxjs';

import { dayjsesToStringDateWhere, getDefaultDate } from 'src/lib/1/date-util';
import { QueryOptions } from 'src/lib/3/firebase-manager';
import { observeSettlementResult } from 'src/lib/4/firebase-short-cut';

import useStores from 'src/hooks/useStores';

import DatePickerWithArrows from 'src/components/Common/DatePickerWithArrows/DatePickerWithArrows';
import SettlementResultTable, {
  SettlementResultTableRowData,
} from 'src/components/NewSettlement/SettlementResultTable/SettlementResultTable';

const defaultValue = getDefaultDate();

const SettlementResultPage: FC = () => {
  const stores = useStores();
  const [date, setDate] = useState<dayjs.Dayjs | null>(defaultValue);
  const [rowData, setRowData] = useState<SettlementResultTableRowData[]>([]);

  useEffect(() => {
    if (date && stores) {
      const [leftDate, rightDate] = dayjsesToStringDateWhere(date, date);
      const options: QueryOptions = {
        sortKey: 'settledAt',
        orderBy: 'desc',
        startValue: leftDate,
        endValue: rightDate,
      };
      const subscription = observeSettlementResult([], options)
        .pipe(
          map((settlementResults) =>
            settlementResults.filter((settlementResult) => settlementResult.deletedAt === null)
          )
        )
        .subscribe((docs) => {
          const settlementResults = docs.map((settlementResult) => {
            const store = stores.find((store) => store._id === settlementResult.storeId);
            return {
              ...settlementResult,
              storeNickname: store?.storeNickname || null,
            };
          });
          setRowData(settlementResults);
        });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [date, stores]);

  return (
    <div className='tabBody flexColumn height100'>
      <section className='flexRow flexSpaceBetween'>
        <DatePickerWithArrows date={date} setDate={setDate} />
      </section>
      <section className='height100'>
        <SettlementResultTable rowData={rowData} />
      </section>
    </div>
  );
};

export default SettlementResultPage;
