import { FilePdfOutlined } from '@ant-design/icons';
import {
  OrderStatusCode,
  PackingInfoProductEntry,
  PaperInvoice,
  ProductsForSupplierEntry,
} from '@gooduncles/gu-app-schema';
import { Button, Tabs } from 'antd';
import { groupBy } from 'lodash-es';
import { FC, useCallback, useEffect, useState } from 'react';
import { combineLatest, map } from 'rxjs';
import { downloadDailyProductList, getPackingInfoProductList, getProductListForSupplier } from 'src/utils/handout-util';
import { generateDeliveryItemsForStore } from 'src/utils/packing-sheet-util';

import { dayjsesToStringDateWhere, formatDate } from 'src/lib/1/date-util';
import { observeOrder, observeStoreIssue } from 'src/lib/4/firebase-short-cut';

import { selectPartnersUsers } from 'src/redux/slices/partners';
import { useAppSelector } from 'src/redux/store';

import useDeliveryRouteHistoryForDate from 'src/hooks/useDeliveryRouteHistoryForDate';
import useStores from 'src/hooks/useStores';
import useSuppliers from 'src/hooks/useSuppliers';
import { useTitle } from 'src/hooks/useTitle';

import classes from './PackingInfoSheetDashboard.module.scss';

import DatePickerWithArrows from '../Common/DatePickerWithArrows/DatePickerWithArrows';
import PrintWindow from '../PrintWindow/PrintWindow';
import EggProductList from './EggProductList/EggProductList';
import ProductsForSupplierList from './ProductsForSupplierList/ProductsForSupplierList';

const PackingInfoSheetDashboard: FC = () => {
  useTitle('GU 관리자 | 패킹 정보 시트');
  const { date, setDate, dateForWhere, deliveryRouteHistory } = useDeliveryRouteHistoryForDate();
  const partnersUsers = useAppSelector(selectPartnersUsers);
  const stores = useStores();
  const suppliers = useSuppliers();
  const [paperInvoices, setPaperInvoices] = useState<PaperInvoice[]>([]);
  const [greenOnionProductList, setGreenOnionProductList] = useState<PackingInfoProductEntry>({});
  const [eggProductList, setEggProductList] = useState<PackingInfoProductEntry>({});
  const [productsForSupplier, setProductsForSupplier] = useState<ProductsForSupplierEntry>({});

  /**
   * 오늘의 주문품목을 다운로드한다.
   */
  const downloadDailyProductListPDF = useCallback(() => {
    downloadDailyProductList(dateForWhere, paperInvoices);
  }, [dateForWhere, paperInvoices]);

  useEffect(() => {
    if (paperInvoices.length > 0 && suppliers) {
      const eggSupplier = suppliers.find((supplier) => supplier.name === '평창양계');
      const greenOnionSupplier = suppliers.find((supplier) => supplier.name === '부여농산');
      setEggProductList(eggSupplier ? getPackingInfoProductList(paperInvoices, eggSupplier, ['store']) : {});
      setGreenOnionProductList(
        greenOnionSupplier ? getPackingInfoProductList(paperInvoices, greenOnionSupplier, ['storeGroup', 'all']) : {}
      );
      setProductsForSupplier(getProductListForSupplier(paperInvoices, suppliers));
    } else {
      setEggProductList({});
      setProductsForSupplier({});
    }
  }, [paperInvoices, suppliers]);

  useEffect(() => {
    if (date && stores && deliveryRouteHistory) {
      const [leftDate, rightDate] = dayjsesToStringDateWhere(date, date);
      const issueDate = formatDate(dateForWhere, "yyyy-MM-dd'T'00:00:00+0900");
      const acceptedOrdersObservable = observeOrder([['orderStatus', '==', OrderStatusCode.ACCEPTED]]);
      const deliveredOrdersObservable = observeOrder([['orderStatus', '==', OrderStatusCode.DELIVERED]], {
        sortKey: 'deliveredAt',
        orderBy: 'desc',
        startValue: leftDate,
        endValue: rightDate,
      });
      const storeIssuesObservable = observeStoreIssue([['date', '==', issueDate]]).pipe(
        map((issues) =>
          issues
            .filter((issue) => issue.isDeleted !== true)
            .filter((issue) => issue.message && issue.status && ['완료', '입력'].includes(issue.status))
        )
      );

      const subscription = combineLatest([
        storeIssuesObservable,
        acceptedOrdersObservable,
        deliveredOrdersObservable,
      ]).subscribe(([storeIssues, acceptedOrders, deliveredOrders]) => {
        const deliveryItemsForStore = generateDeliveryItemsForStore(
          stores,
          [...acceptedOrders, ...deliveredOrders],
          storeIssues
        );

        const { date, deliverySpots } = deliveryRouteHistory;
        const dateKr = formatDate(date, 'yyyy년 L월 dd일');
        const groupByPartnerId = Object.entries(
          groupBy(
            deliverySpots.map((deliverySpot) => {
              // 배송 지점에 속한 매장
              const deliveryStoresWithItems = deliveryItemsForStore.filter(
                (item) => item.deliverySpotId === deliverySpot._id
              );
              return {
                ...deliverySpot,
                deliveryStoresWithItems,
              };
            }),
            'partnerId'
          )
        );

        const sortedDeliverySpots = groupByPartnerId
          .map(([partnerId, deliverySpots]) => {
            const partner = partnersUsers.find((partner) => partner._id === partnerId);
            return { partner, deliverySpots };
          })
          .sort(
            (a, b) => (a.partner?.deliveryDriverStatus.sortKey ?? 0) - (b.partner?.deliveryDriverStatus?.sortKey ?? 0)
          );

        setPaperInvoices(
          sortedDeliverySpots.flatMap(({ partner, deliverySpots }) => {
            // 담당자의 배송지 목록 -> 배송지 목록의 매장 목록
            return deliverySpots
              .map((spot) =>
                spot.deliveryStoresWithItems.map((item) => ({
                  ...item,
                  partnerName: partner?.name ?? '미할당',
                  sortKey: partner?.deliveryDriverStatus.sortKey ?? 0,
                  date: dateKr,
                }))
              )
              .flat();
          })
        );
      });

      return () => subscription.unsubscribe();
    } else {
      setPaperInvoices([]);
    }
  }, [dateForWhere, deliveryRouteHistory, date, stores, partnersUsers]);

  return (
    <div className={classes.packingInfoDashboardContainer}>
      <div className={classes.dashboardToolbar}>
        <DatePickerWithArrows date={date} setDate={setDate} />
        <Button
          type='primary'
          icon={<FilePdfOutlined />}
          disabled={paperInvoices.length === 0}
          onClick={downloadDailyProductListPDF}>
          {dateForWhere} 주문품목
        </Button>
      </div>
      <Tabs
        type='card'
        style={{ height: '100%', overflow: 'scroll' }}
        tabBarStyle={{ marginBottom: 0 }}
        items={[
          {
            key: 'eggProductList',
            label: '계란 상품별',
            children: <PrintWindow>{eggProductList && <EggProductList data={eggProductList} />}</PrintWindow>,
          },
          {
            key: 'greenOnionProductList',
            label: '대파 상품별',
            children: (
              <PrintWindow>{greenOnionProductList && <EggProductList data={greenOnionProductList} />}</PrintWindow>
            ),
          },
          {
            key: 'productsForSupplier',
            label: '매입처별',
            children: (
              <PrintWindow>{productsForSupplier && <ProductsForSupplierList data={productsForSupplier} />}</PrintWindow>
            ),
          },
        ]}
      />
    </div>
  );
};

export default PackingInfoSheetDashboard;
